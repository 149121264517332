import { ContractProcessState } from "./contract-process-state.model";
import {FirstCheckState} from "./first-check-state.model";
import {CarLeasingTicketCreation} from "./car-leasing-ticket-creation.model";

export interface DrivingLicenseFileApiResponse {
  back: string;
  front: string;
  selfie: string;
}

export interface UploadRequestsBySlotIdApiResponse {
  front?: UploadRequestApiResponse;
  selfie?: UploadRequestApiResponse;
  back?: UploadRequestApiResponse;
}

export interface UploadRequestApiResponse {
  comment: string;
  fileId: string;
}

export class CarLeasingBasket {

  public customer = null;
  public employee = null;

  public constructor(
    public id: string,
    public employeeId: number,
    public customerId: number,
    public firstCheckState: FirstCheckState,
    public orderId: string,
    public firstCheckFiles: {
      drivingLicenseFiles: DrivingLicenseFileApiResponse,
      uploadRequestsBySlotId: UploadRequestsBySlotIdApiResponse
    },
    public checkoutDate: string,
    public preferredDeliveryDate: string,
    public isReadyToSign: boolean,
    public contractProcessData: {
      fileId: string,
      state: ContractProcessState,
      comment: string,
      process_type: string
    },
    public contractSigningProcess: any,
    public otrs: CarLeasingTicketCreation|null,
    public checkoutConfiguration: object,
    public approvalState: string,
    public deliveryPhone: string,
    public isCancelled: boolean,
  ) {}

  // Lex: these methods exists as long as we have javascript code that cannot deal with typescript enums (or can it?)
  public isAwaitingFirstCheckApproval() {
    return this.firstCheckState == FirstCheckState.AwaitingApproval;
  }

  public isFirstCheckApproved() {
    return this.firstCheckState == FirstCheckState.Approved;
  }

  public isFirstCheckRejected() {
    return this.firstCheckState == FirstCheckState.Rejected;
  }

  public isWaitingForFirstCheckUpload() {
    return this.firstCheckState == FirstCheckState.AwaitingUpload;
  }

  public isAwaitingContractProcessApproval() {
    return this.contractProcessData.state == ContractProcessState.AwaitingApproval
  }

  public isContractProcessApproved() {
    return this.contractProcessData.state == ContractProcessState.Approved
  }

  public isWaitingForNewContractUpload() {
    return this.contractProcessData.state == ContractProcessState.AwaitingUpload
  }

  public isApprovalStateApproved() {
    return this.approvalState === 'approved';
  }

  public setCustomerData(customer: any) {
    this.customer = customer;
  }

  public setEmployeeData(employee: any) {
    this.employee = employee;
  }
}
