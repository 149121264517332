export const EMPLOYEE_ADMINISTRATION_DEFAULT_FILTERS = {
  id: '',
  custId: '',
  name: '',
  personal_number: '',
  email: '',
  yearly_state: {
    selected: [],
    options: [{
      display: 'Offen',
      id: 'opened'
    }, {
      display: 'Abgeschlossen',
      id: 'finished'
    }]
  },
  made_status: {
    selected: [],
    options: [{
      name: 'Aktiv',
      id: 'active'
    }, {
      name: 'Eingeladen',
      id: 'invited'
    }, {
      name: 'Deaktiviert',
      id: 'deactivated'
    }, {
      name: 'Inaktiv ohne @',
      id: 'red'
    }, {
      name: 'inaktiv mit @',
      id: 'blue'
    }, {
      name: 'Bikeleasing',
      id: 'bikeleasing'
    }, {
      name: 'PC-Leasing',
      id: 'pc-leasing'
    }]
  },
  last_login: {
    selected: [],
    options: [{
      display: 'innerhalb des letzten Monats',
      name: '0_month',
      months_range: {
        upper_limit: 1,
        lower_limit: 0
      }
    }, {
      display: 'vor 1 - 3 Monaten',
      name: '1_month',
      months_range: {
        upper_limit: 3,
        lower_limit: 1
      }
    }, {
      display: 'vor 3 - 6 Monaten',
      name: '3_month',
      months_range: {
        upper_limit: 6,
        lower_limit: 3
      }
    }, {
      display: 'vor mehr als 6 Monaten',
      name: '6_month',
      months_range: {
        upper_limit: false,
        lower_limit: 6
      }
    }]
  },
  order_by_name: '',
  enable_timestamp: false
};

export const DEFAULT_BIKELEASING_CHECKOUT_CONFIG = {
  valuenet_id: undefined,
  begin_date: undefined,
  monthly_amount: undefined,
  contract_number: undefined,
  tax_base_amount: undefined,
  full_amount: undefined,
  validation_url: undefined,
  ticket_number: undefined,
  validation_code: '',
  contract_id: undefined,
  file: undefined,
};

export const APPROVAL_PROCESS_TYPES = [
  {
    'id': 0,
    'name': 'neo_bikeleasing',
    'display': 'Bikeleasinganfrage',
    'value_type': 'Bruttoleasingumfang',
    'settings': {
      'date_key': 'entity.dates.contract_begin_date',
      'value_key': 'entity.full_amount',
      'monthly_value_key': 'entity.per_month_value',
      'entity': 'neo_bikeleasing',
      'duration': 36
    }
  },
  {
    'id': 1,
    'name': 'neo_pc',
    'display': 'PC-Leasinganfrage',
    'value_type': 'Bruttoleasingumfang',
    'settings': {
      'date_key': 'entity.beginDate',
      'value_key': 'entity.brutto_base',
      'monthly_value_key': 'entity.leasing',
      'entity': 'mpp_basket',
      'duration': 24
    }
  },
  {
    'id': 2,
    'name': 'bonus_bikeleasing',
    'display': 'Fahrradleasing',
    'value_type': 'Bruttoleasingumfang',
    'settings': {
      'date_key': 'entity.dates.bonus_start_date',
      'value_key': 'entity.checkout_config.full_amount',
      'monthly_value_key': 'entity.checkout_config.per_month_value',
      'entity': 'bonus',
      'duration': 36
    }
  },
  {
    'id': 3,
    'name': 'bonus_pc',
    'display': 'PC-Leasing',
    'value_type': 'Bruttoleasingumfang',
    'settings': {
      'date_key': 'entity.beginDate',
      'value_key': 'entity.sum',
      'monthly_value_key': 'entity.leasing',
      'entity': 'mpp_basket',
      'duration': 24
    }
  },
  {
    'id': 4,
    'name': 'neo_pc_component',
    'display': 'PC-Leasinganfrage',
    'value_type': 'Bruttoleasingumfang',
    'settings': {
      'date_key': 'entity.beginDate',
      'value_key': 'entity.brutto_base',
      'monthly_value_key': 'entity.leasing',
      'entity': 'mpp_basket',
      'duration': 24
    }
  }
];

export const APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES.reduce((acc, process_type) => {
  acc[process_type['name']] = process_type;
  return acc;
}, {});

export const APPROVAL_PROCESS_TYPES_BY_ID = APPROVAL_PROCESS_TYPES.reduce((acc, process_type) => {
  acc[process_type['id']] = process_type;
  return acc;
}, {});

export const APPROVAL_PROCESS_STATES = [
  {
    'id': 0,
    'name': 'waiting_external_approval',
    'display': 'Freigabeanfrage',
    'settings': {
      'is_present_in_filter': true
    }
  },
  {
    'id': 1,
    'name': 'external_approved',
    'display': 'Freigabe durch Kunde',
    'settings': {
      'is_present_in_filter': true
    }
  },
  {
    'id': 2,
    'name': 'external_declined',
    'display': 'Ablehnung durch Kunde',
    'settings': {
      'is_present_in_filter': true
    }
  },
  {
    'id': 3,
    'name': 'internal_approved',
    'display': 'Freigabe Erteilt',
    'settings': {
      'is_present_in_filter': false
    }
  },
  {
    'id': 4,
    'name': 'internal_declined',
    'display': 'Internal Abgelehnt',
    'settings': {
      'is_present_in_filter': false
    }
  },
  {
    'id': 5,
    'name': 'automatically_declined',
    'display': 'Automatisch abgelehnt',
    'settings': {
      'is_present_in_filter': true
    }
  }
];

export const APPROVAL_PROCESS_STATES_BY_ID = APPROVAL_PROCESS_STATES.reduce((acc, state) => {
  acc[state.id] = state;
  return acc;
}, {});

export const APPROVAL_PROCESS_STATES_BY_NAME = APPROVAL_PROCESS_STATES.reduce((acc, state) => {
  acc[state.name] = state;
  return acc;
}, {});

export const APPROVAL_PROCESS_ACTIVE_STATE_IDS = [APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id']];

export const APPROVAL_PROCESS_FILTER_CONFIGURATION = {
  approval_state: {
    selected: [],
    options: [...APPROVAL_PROCESS_STATES
      .filter(state => {
        return state['settings']['is_present_in_filter'];
      })
    ]
  }
};

export const NEO_PC_APPROVAL_PROCESS_STATE_FILTER_CONFIGURATION = {
  neo_pc_state: {
    selected: [],
    options: [{
      'id': 1,
      'name': 'approved_from_bo',
      'display': 'Freigabe durch BO',
    }, {
      'id': 2,
      'name': 'not_approved_from_bo',
      'display': 'Ablehnung durch BO',
    }]
  }
};

export const APPROVAL_PROCESS_FLOWS = [
  {
    'id': 0,
    'name': 'external_approve',
    'display': 'External Approve',
    'transitions': {
      [APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id']]: {
        'to': APPROVAL_PROCESS_STATES_BY_NAME['external_approved']['id']
      }
    }
  },
  {
    'id': 1,
    'name': 'external_decline',
    'display': 'External Decline',
    'transitions': {
      [APPROVAL_PROCESS_STATES_BY_NAME['waiting_external_approval']['id']]: {
        'to': APPROVAL_PROCESS_STATES_BY_NAME['external_declined']['id']
      }
    }
  },
  {
    'id': 2,
    'name': 'internal_approve',
    'display': 'Internal Approve',
    'transitions': {
      [APPROVAL_PROCESS_STATES_BY_NAME['external_approved']['id']]: {
        'to': APPROVAL_PROCESS_STATES_BY_NAME['internal_approved']['id']
      },
      [APPROVAL_PROCESS_STATES_BY_NAME['external_declined']['id']]: {
        'to': APPROVAL_PROCESS_STATES_BY_NAME['internal_declined']['id']
      }
    }
  },
  {
    'id': 3,
    'name': 'internal_decline',
    'display': 'Internal Decline',
    'transitions': {
      [APPROVAL_PROCESS_STATES_BY_NAME['external_declined']['id']]: {
        'to': APPROVAL_PROCESS_STATES_BY_NAME['internal_declined']['id']
      }
    }
  }
];


export const USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES = [
  {'id': 0, 'name': 'button', 'display': 'Button'}
];

export const USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES_BY_NAME = USER_DECISION_PROCESS_OPTIONS_DISPLAY_WIDGET_TYPES.reduce((acc, widget) => {
  acc[widget['name']] = widget;
  return acc;
}, {});

export const USER_DECISION_PROCESS_STATES = [
  {'id': 0, 'name': 'created', 'display': 'Created', 'description': 'The process was createad'},
  {
    'id': 1, 'name': 'waiting_for_decision', 'display': 'Waiting for decision',
    'description': 'Waiting for user decision'
  },
  {
    'id': 2, 'name': 'user_has_decided', 'display': 'User has decided',
    'description': 'The user has done his decision'
  },
];
export const USER_DECISION_PROCESS_STATES_BY_NAME = USER_DECISION_PROCESS_STATES.reduce((acc, state) => {
  acc[state['name']] = state;
  return acc;
}, {});
export const USER_DECISION_PROCESS_STATES_BY_ID = USER_DECISION_PROCESS_STATES.reduce((acc, state) => {
  acc[state['id']] = state;
  return acc;
}, {});
export const USER_DECISION_PROCESS_OPEN_STATES = [USER_DECISION_PROCESS_STATES_BY_NAME['waiting_for_decision']['id']];


export const APPROVAL_PROCESS_FLOWS_BY_NAME = APPROVAL_PROCESS_FLOWS.reduce((acc, flow) => {
  acc[flow['name']] = flow;
  return acc;
}, {});


const $inject = [
  'Made',
  'vbmService'
];

export default class AdministrationService {
  constructor(Made, vbmService) {
    Object.assign(this, {
      Made,
      vbmService
    });
  }

  synchronizeBikeleasing() {
    return this.Made.request('rpc://valuenetdb/bonus/sync_bikeleasing');
  }

  checkAndUpdateUsedLimitMultiple(customer_ids) {
    return this.Made.request('rpc://vbm/mpp/checkAndUpdateUsedLimitMultiple', {'customer_ids': customer_ids});
  }

  async getEmployeePersonalInfo(valuenet_id) {
    return this.Made.request('rpc://administration/control_process/get_employee_personal_info', {
      valuenet_id: valuenet_id
    });
  }

  async getEmployeeTaxInfo(valuenet_id) {
    return this.Made.request('rpc://administration/control_process/get_employee_tax_info', {
      valuenet_id: valuenet_id
    });
  }

  async getEmployeeOptimization(valuenet_id) {
    return this.Made.request('rpc://administration/control_process/get_employee_optimization', {
      valuenet_id: valuenet_id
    });
  }

  async approvalExternalDecline(options) {
    let params = Object.assign({}, {
      process_id: options.approval_id,
      log_comment: options.log_comment
    });
    return this.Made.request('rpc://administration/approval_process/external_decline', params);
  }

  async approvalExternalApprove(options) {
    let params = Object.assign({}, {
      process_id: options.approval_id
    });
    return this.Made.request('rpc://administration/approval_process/external_approve', params);
  }

  //TODO: VN-2907 I think this is not used anymore and has to be deleted
  async createApprovalProcessNotificationAndEmail(options) {
    let params = {
      approval_process_notification_name: options['approval_process_notification_name'],
      approval_process_id: options['approval_process_id'],
      caller: options['caller'],
      comment: options['comment']
    };

    return this.Made.request('rpc://valuenetdb/notification/create_approval_process_notification_and_email', params);
  }

  async getPendingApprovals(options) {
    let params = Object.assign({}, {
      employee_id: options.employee_id,
      type_ids: options.type_ids
    });
    return this.Made.request('rpc://administration/approval_process/get_pending_approvals', params);
  }

  async getApprovalsByCalculation(options) {
    let params = Object.assign({}, {
      session_id: options.session_id
    });

    return this.Made.request('rpc://administration/approval_process/get_approvals_by_session_id', params);
  }

  async approvalInternalApprove(options) {
    let params = Object.assign({}, {
      process_id: options.process_id
    });

    return this.Made.request('rpc://administration/approval_process/internal_approve', params);
  }

  async approvalInternalDecline(options) {
    let params = Object.assign({}, {
      process_id: options.process_id,
      log_comment: options.log_comment
    });

    return this.Made.request('rpc://administration/approval_process/internal_decline', params);
  }

  async approvalUpdateProcess(options) {
    let params = {
      process: options.process,
      log_comment: options.comment,
      updated_begin_date: options.begin_date
    };

    return this.Made.request('rpc://administration/approval_process/update_approval_process', params);

  }

  async getProcessesReadyForApproval() {
    return this.Made.request('rpc://administration/approval_process/get_processes_for_approval')
  }

  async canApproveCarLeasing( customerId) {
    return this.Made.request('rpc://administration/approval_process/can_approve_decline_car_leasing_process', {
      customer_id: customerId
    });
  }

  async getApprovalProcesess(options = {
    employee_ids: undefined,
    type_ids: undefined,
    to_extract_entity: false,
    to_extract_employee: false,
    to_add_validations: false,
    state_id: undefined,
    entity_ids: undefined
  }) {
    let params = Object.assign({}, {
      employee_ids: options.employee_ids,
      type_ids: options.type_ids,
      to_extract_entity: options.to_extract_entity,
      to_extract_employee: options.to_extract_employee,
      to_add_validations: options.to_add_validations,
      state_id: options.state_id,
      entity_ids: options.entity_ids
    });

    return this.Made.request('rpc://administration/approval_process/get_processes', params);
  }

  async getApprovalProcessLog(options = {
    process_id: undefined,
    entity_id: undefined,
    type_id: undefined,
    only_last: undefined,
    limit: undefined
  }) {
    let params = Object.assign({}, {
      process_id: options.process_id,
      entity_id: options.entity_id,
      type_id: options.type_id,
      only_last: options.only_last,
      limit: options.limit
    });

    return this.Made.request('rpc://administration/approval_process/get_approval_process_log', params);
  }

  canApproveProcess(process_id) {
    return this.Made.request('rpc://administration/approval_process/can_approve_decline_process', {
      process_id: process_id
    });
  }

  async acceptApplication(valuenet_id, comment = '', digital_signing = null) {

    return this.Made.request('rpc://administration/control_process/accept_application', {
      valuenet_id: valuenet_id,
      comment: comment,
      digital_signing: !!digital_signing ? {
        is_enabled: digital_signing['isEnabled'],
        ticket_number: digital_signing['ticketNumber'],
        expiration_date: digital_signing['expirationDate'],
      } : null
    });
  }

  async moveToKontrolleP6(valuenet_id) {
    return this.Made.request('rpc://administration/control_process/move_to_kontrolle_p6', {
      valuenet_id: valuenet_id
    })
  }

  async ammendCalculation(valuenet_id, comment) {
    return this.Made.request('rpc://administration/control_process/decline_application', {
      valuenet_id: valuenet_id,
      comment: comment
    });
  }

  async requireNeoDocuments(valuenet_id, payload) {
    return this.Made.request('rpc://administration/control_process/require_neo_documents', {
      valuenet_id: valuenet_id,
      payload: payload
    });
  }

  async sendControlProcessEmail(valuenet_id, email_template_name, extra = {}) {
    return this.Made.request('rpc://administration/control_process/send_control_process_email', {
      valuenet_id: valuenet_id,
      email_template_name: email_template_name,
      extra: extra
    });
  }

  async lockEmployee(valuenet_id) {
    return this.Made.request('rpc://administration/control_process/lock_employee', {valuenet_id: valuenet_id});
  }

  async unlockEmployee(valuenet_id) {
    return this.Made.request('rpc://administration/control_process/unlock_employee', {valuenet_id: valuenet_id});
  }

  async getAllLockedEmployees(filtered_ids) {
    return this.Made.request('rpc://administration/control_process/get_all_locked_employees', {filtered_ids: filtered_ids});
  }

  async getEmployeesWithPendingPCApproval(filtered_ids) {
    return this.Made.request('rpc://administration/approval_process/get_employees_with_pending_neo_pc_approvals', {filtered_ids: filtered_ids});
  }

  async createEVGContractDocument(employee_id, calc_id) {
    return this.Made.request('rpc://utility/pdfgeneratorapi/create_evg_contract_document', {
      employee_id: employee_id,
      calc_id: calc_id
    });
  }

  async saveEVGTemplates(templates, customer_id = null) {
    return this.Made.request('rpc://administration/evg_templates/saveEVGTemplate', {
      templates: templates,
      customer_id: customer_id
    });
  }

  async decisionProcessChooseOption(options) {
    let params = {
      process_id: options.process_id,
      choice_value_id: options.choice_value_id
    };

    return this.Made.request('rpc://administration/user_decision_process/choose_option', params);
  }

  async getOpenMakuUserDecisionProcessForEmployee(employeeId) {
    return this.Made.request('rpc://administration/user_decision_process/get_open_user_decision', {
      employee_id: employeeId
    });
  }

  async EHBemployeeChoice(makuDecisionProcess, isMaku) {
    return this.Made.request('rpc://administration/user_decision_process/employee_maku_choice', {
      user_decision_id: makuDecisionProcess.id,
      employee_id: makuDecisionProcess.employee,
      is_maku: isMaku
    });
  }

  async getOpenDecisionProcessesForEmployee(options) {
    let params = {
      employee_ids: [options.employee_id],
      to_extract_entity: options.to_extract_entity || false,
      state_ids: USER_DECISION_PROCESS_OPEN_STATES
    };

    return this.getUserDecisionProcesses(params);
  }

  async getUserDecisionProcesses(options) {
    let params = {
      employee_ids: options.employee_ids,
      state_ids: options.state_ids,
      to_extract_entity: options.to_extract_entity || false
    };

    return this.Made.request('rpc://administration/user_decision_process/get_processes', params);
  }

  async createBikeleasingBonusDocument(employee_id, user_current_datetime_str) {
    return this.Made.request('rpc://utility/pdfgeneratorapi/create_bikeleasing_bonus_document', {
      employee_id: employee_id,
      user_current_datetime_str: user_current_datetime_str
    });
  }

  async createValidationCodesDocument() {
    return this.Made.request('rpc://utility/pdfgeneratorapi/create_validation_code_document');
  }

  async getDocumentTemplateType() {
    return this.Made.request('rpc://administration/document_templates/get_document_template_types');
  }

  async getDefaultComponentTemplates(template_type) {
    return this.Made.request('rpc://administration/document_templates/get_component_templates', {
      template_type: template_type
    });
  }

  async getDocumentTemplates(template_type, customer_id = null) {
    return this.Made.request('rpc://administration/document_templates/get_document_templates', {
      template_type: template_type,
      customer_id: customer_id
    });
  }

  async saveDocumentTemplate(template, customer_id = null) {
    return this.Made.request('rpc://administration/document_templates/save_document_template', {
      template: template,
      customer_id: customer_id
    });
  }

  async resetDefaultTemplates() {
    return this.Made.request('rpc://administration/document_templates/reset_default_evg_templates');
  }

  generateContract(basket) {
    return this.Made.request('rpc://vbm/neo/pc/generateContract', { basket_id: basket['_id'] })
  }

  async requestCustomerDocuments(options) {
    let parameters = {
      customer_id: options.customer_id,
      document_type_name: options.document_type_name,
      secondary_document_type: options.secondary_document_type,
      yearly_session_id: options.yearly_session_id
    };

    return this.Made.request('rpc://administration/documents_download_requests/request_customer_documents', parameters
    );
  }

  getActiveDocumentRequests(customer_id) {
    return this.Made.request('rpc://administration/documents_download_requests/get_active_document_requests', {
      customer_id: customer_id
    });
  }

  getDocumentRequestStates() {
    return this.Made.request('rpc://administration/documents_download_requests/get_documents_download_request_states');
  }

  getDocumentTypes() {
    return this.Made.request('rpc://administration/documents_download_requests/get_document_types')
  }

  cancelNeoDigitalSigning(employee_id, comment) {
    return this.Made.request('rpc://administration/control_process/cancel_neo_digital_signing', {
      'employee_id': employee_id,
      'comment': comment
    })
  }

  //temporary
  send_missing_approval_approved_email() {
    return this.Made.request('rpc://administration/approval_process/send_missing_approval_approved_email');
  }
}

AdministrationService.$inject = $inject;
