import lodash from 'lodash';
import moment from 'moment';
import {ComponentModel} from './component-model';
import {NeoComponentState} from './neo-component-state';
import {ShopApiService} from '../services/backend/shop-api.service';


// #COMMENT_LEX: I haven't changed much here since I worked from the inside out (starting with Article -- former
// ArticleData --, then BasketItem -- former BasketArticle -- and finally Basket. I did not have enough time to
// refactor this class, too. However, it's basically always the same:
// - The dependencies to injector and other service should be removed completely.
// - 'constants' dependency should be removed
// - Fields should be passed explicitly in the constructor
// - unused methods should be removed
// - some basic tests can be added
//
export class PCComponentModel extends ComponentModel {

  static async getAllWithMissingDocuments(injector, options) {
    let Made = injector.get('Made');
    let VSP_CONSTANTS = injector.get('VSP_CONSTANTS');
    let params = {
      // component_state_id: VSP_CONSTANTS.NEO_COMPONENT_STATE_BY_NAME['awaiting_documents_gather']['id'],
      employee_id: options.employee_id
    };
    let pc_components = await Made.request('rpc://vbm/neo/pc/get_missing_documents', params);
    pc_components = pc_components['data'].map( pc_component => new PCComponentModel(pc_component, VSP_CONSTANTS, injector) );
    return pc_components;
  }

  static async getAvailable(injector, options) {
    let Made = injector.get('Made');
    let VSP_CONSTANTS = injector.get('VSP_CONSTANTS');
    let params = {
      employee_id: options.employee_id
    };
    let pc_component = await Made.request('rpc://vbm/neo/pc/getAvailable', params);
    if (pc_component) {
      return new PCComponentModel(pc_component, VSP_CONSTANTS, injector);
    }
  }

  static async createNew(injector, options) {
    let Made = injector.get('Made');
    let VSP_CONSTANTS = injector.get('VSP_CONSTANTS');
    let params = {
      employee_id: options.employee_id
    };
    let pc_component = await Made.request('rpc://vbm/neo/pc/createNew', params);
    return new PCComponentModel(pc_component, VSP_CONSTANTS, injector);
  }

  static get ID () {
    return 'pc';
  }

  static get DISPLAY () {
    return {
      video : 'https://player.vimeo.com/video/397980851?dnt=1',
      pic_id: 'pc',
      id: PCComponentModel.ID,
      title : 'PC-Leasing Shop',
      subtitle: 'Steuerfreie Elektronik',
      description: 'Mit dem Baustein „PC - Leasing“ können Sie Ihren modernen Lifestyle sowie Ihren Sinn für Ästhetik und Technik unterstreichen – ohne sich finanziell einschränken zu müssen. Keine Frage: Wer möchte nicht gerne den neuesten Laptop, das neueste Smartphone, das neueste IPad besitzen. IT Technik ist aus unserem täglichen Leben nicht mehr weg zu denken, wäre da nicht die Höhe der Anschaffungskosten – bisher. Ihr Arbeitgeber kann Ihnen nun den Erwerb eines aktuellen Apple-Gerätes oder eines anderen Herstellers in Form eines exklusiven Vergütungsbausteins ermöglichen. Zu den Geräten zählen: Alle aktuellen Apple Geräte + Zubehör Notebooks von Lenovo, Asus, HP, Acer, Dell etc. Tablets von Sony, HP, Samsung, Asus, Lenovo etc. Smartphones von LG, Samsung, Nokia, Sony, HTC, Blackberry etc.'
    };
  }

  /**
   * @param options
   * @param constants
   * @param injector
   */
  constructor(options, constants, injector) {
    super({
      component_name: PCComponentModel.ID,
      customer_component_configuration: options.customer_component_configuration,
      neo_project_component_configuration: options.neo_project_component_configuration,
      state_id: options.state,
      constants: constants,
      injector: injector,
      employee_id: options.employee_id
    });

    // COMMENT_LEX: The basket should be passed as a separate parameter (and as a Basket object)
    this._basket = options.basket ? ShopApiService.makeBasket(options.employee_id, options.basket) : null;


    this.setViewProperties();
  }

  async calculateAdvantage() {
    let vbmService = this._injector.get('vbmService');

    let last_calculation = await vbmService.getLatestCalculation(this.employee_id);
    last_calculation.neo_components.pc.value = this.per_month_value;
    last_calculation.neo_components.pc.enabled = true;

    let advantage = await vbmService.getSingleResult(last_calculation, 'pc');
    this._advantage = advantage;

    return this.advantage;
  }

  get per_month_value () {
    return this.basket.leasing_rate;
  }

  get advantage() {
    return lodash.cloneDeep(this._advantage);
  }

  setViewProperties() {
    this._is_available = this._state_id === NeoComponentState.AVAILABLE || angular.isUndefined(this._state_id);
    this._is_active = this._state_id === NeoComponentState.ACTIVE;
    this._is_for_bo_control =  this._state_id === NeoComponentState.AWAITING_BO_CONTROL;
    let order_process = lodash.get(this.basket, '_orderProcess');
    let order_process_state_id = lodash.get(order_process, 'state_id');
    this._is_order_delivered = [
      this.constants.MPP_ORDER_STATUS_BY_NAME['order_closed']['id'],
      this.constants.MPP_ORDER_STATUS_BY_NAME['bill_send_leasing_company']['id'],
      this.constants.MPP_ORDER_STATUS_BY_NAME['delivered']['id'],
    ].includes(order_process_state_id);
    this._is_order_waiting = order_process && !this._is_order_delivered;
    this._order_process_display = lodash.get(this.constants.MPP_ORDER_STATUS_BY_ID, `${order_process_state_id}.display`);
    this._create_date_display = moment.unix(lodash.get(this.basket, 'created')).format('DD.MM.YYYY');
    this._order_id = lodash.get(this.basket, '_orderProcess.order_id');
    this._contract_id = lodash.get(this.basket, '_contractId');
    this._pick_up_date = lodash.get(this.basket, '_confirmation.pick_up_date');
    this._pick_up_date_display = this._pick_up_date ? moment(this._pick_up_date).format('DD.MM.YYYY') : undefined;
    this._contract_start_date_display = (this.basket && this.basket.contract_start_date) ? this.basket.contract_start_date.format('DD.MM.YYYY') : undefined;
    this._contract_end_date_display = (this.basket && this.basket.contract_end_date) ? this.basket.contract_end_date.format('DD.MM.YYYY') : undefined;
  }

  get contract_start_date_display () {
    return this._contract_start_date_display;
  }

  get contract_end_date_display () {
    return this._contract_end_date_display;
  }

  get pick_up_date_display() {
    return this._pick_up_date_display;
  }

  get component_state_display() {
    return this._state.display;
  }

  get contract_id () {
    return this._contract_id;
  }

  get order_id () {
    return this._order_id;
  }

  get file_tags() {
    let result = [
      'neo',
      this.id,
      'neo_component',
      'neo_component_name-' + this.id,
      'done'
    ];
    if (this._basket) {
      result.push('basket_id-' + this._basket.id);
    }
    return result;
  }

  get created_date_display() {
    return this._create_date_display;
  }

  get order_process_display () {
    return this._order_process_display;
  }

  get is_order_delivered() {
    return this._is_order_delivered;
  }

  get is_order_waiting () {
    return this._is_order_waiting;
  }

  async uploadDocuments() {
    let promises = [];
    if (angular.isArray(this._files_for_upload) && this._files_for_upload.length > 0) {
      let tags = lodash.cloneDeep(this.file_tags);
      for (let [i, file] of this._files_for_upload.entries()) {
        promises.push(
          this._API.upload(file, [...tags, `upload_index-${i}`])
        );
      }

      delete this._files_for_upload;

      if (this.state.id === NeoComponentState.AWAITING_DOCUMENTS_GATHERING) {
        // move to CP
        promises.push(
          this._finish_documents_gather_process()
        );
      }
    }

    return Promise.all(promises);
  }

  async _finish_documents_gather_process() {
    let params = {
      basket_id: this.basket.id
    };
    return this._API.request('rpc://vbm/neo/pc/_finishDocumentsGatherProcess', params);
  }

  get files_for_upload() {
    if (!angular.isArray(this._files_for_upload)) {
      this._files_for_upload = [];
    }

    return this._files_for_upload;
  }

  async getComponentFiles(options) {
    let file_tags = [
      'neo_component_name-' + this.id,
      'basket_id-' + this._basket.id,
    ];
    let userService =  this._injector.get('userService');
    let basket_uploaded_files = await userService.getUploadedFiles(undefined, {valuenet_id: this.employee_id, tags: file_tags, for_neo_component: true});
    return basket_uploaded_files;
  }

  async generateContract() {
    let params = {
      basket_id: this._basket.id
    };
    return this._API.request('rpc://vbm/neo/pc/generateContract', params);
  }

  async canDoCheckout() {
    let params = {
      employee_id: this.employee_id
    };
    this.can_do_checkout = await this._API.request('rpc://vbm/neo/pc/canCheckout', params);
    return this.can_do_checkout;
  }

  async cp_approve(options){
    let params = {
      basket_id: this._basket.id,
      sent_for_delivery_date: options.sent_for_delivery_date
    };
    return this._API.request('rpc://vbm/neo/pc/CPApprove', params);
  }

  async cp_decline(options) {
    let params = {
      basket_id: this._basket.id,
      comment: options.comment
    };
    return this._API.request('rpc://vbm/neo/pc/CPDecline', params);
  }

  async cp_amend(options) {
    let params = {
      basket_id: this._basket.id,
      comment: options.comment
    };
    return this._API.request('rpc://vbm/neo/pc/CPAmend', params);
  }

  async doCheckout() {
    let can_do_checkout = await this.canDoCheckout();

    if (!can_do_checkout) {
      throw 'can not do checkout';
    }

    let link = 'rpc://vbm/neo/pc/checkout';
    let params = {
      employee_id: this.employee_id,
      basket_id: this.basket.id
    };

    return this._API.request(link, params).then(pc_component => {
      if (pc_component){
        this._set_state_by_id(pc_component['component_state_id']);
        this._basket = ShopApiService.makeBasket(this.employee_id, pc_component['basket']);
      }
    });
  }

  async preend_pc(preend_information) {
    let params = {
      basket_id: this._basket.id,
      preend_date: preend_information.preend_date,
      comment: preend_information.comment,
      ticket_number: preend_information.ticket_number,
      force_buying_process: preend_information.force_buying_process
    };
    return this._API.request('rpc://vbm/neo/pc/preendPC', params);
  }

  async remove_preend_on_pc(comment) {
    let params = {
      basket_id: this._basket.id,
      comment: comment
    };
    return this._API.request('rpc://vbm/neo/pc/removePreendOnPC', params);
  }

  get is_used () {
    return !this.is_available;
  }

  get is_active() {
    return this._is_active;
  }

  get is_for_bo_control() {
    return this._is_for_bo_control;
  }

  get basket() {
    return this._basket;
  }

  set basket(b) {
    this._basket = b;
  }

  get is_total_brutto_valid() {
    return this.basket.total_brutto >= this.customer_component_configuration.mindestbestellwert;
  }

  get is_available() {
    return this._is_available;
  }

  get display( ) {
    return PCComponentModel.DISPLAY;
  }

  get netto_value() {
    return this._basket.value_netto;
  }

  getContractStartDate(format) {
    if (!format) {
      return this._basket.contract_start_date.toDate();
    }

    return this._basket.contract_start_date.format(format);
  }

  getContractEndDate(format) {
    if (!format) {
      return this._basket.contract_end_date.toDate();
    }

    return this._basket.contract_end_date.format(format);
  }

  totalBrutto() {
    return this.total_brutto;
  }

  perMonthValue() {
    return this.per_month_value;
  }

  get tax_value() {
    return 0;
  }

  get total_brutto() {
    return this._basket.total_brutto;
  }

  get video() {
    return this.display.video;
  }

  get title() {
    return this.display.title;
  }

  async insertContactInformation(contact_information) {
    let link = 'rpc://vbm/neo/pc/insertContactInformation';
    let params = {
      employee_id: this.employee_id,
      basket_id: this.basket.id,
      contact_information: contact_information,
    };

    return this._API.request(link, params);
  }
}
