const ComponentConstants = {
  handy: {
    title: 'Mobiltelefon',
    icon:  'tablet',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  essenscheck: {
    title: 'Essensschecks',
    icon:  'coupons',
    usage: {
      list: [
        'digital',
        'paper'
      ],
      default: 'digital'
    }
  },
  ES_DEM: {
    title: 'Essensschecks Digital',
    icon:  'tablet',
  },
  ES_PAPIER: {
    title: 'Essensschecks Papier',
    icon:  'coupons',
  },
  sachbezug: {
    title: 'Sachbezug',
    icon:  'company',
    usage: {
      list: [
        'valuecard',
        'vorteilskonto'
      ],
      default: 'valuecard'
    }
  },
  EBike: {
    title: 'Jobrad',
    icon:  'bike',
    usage: {
      list: [
        'sachbezug'
      ],
      default: 'sachbezug'
    }
  },
  Bikeleasing: {
    title: 'Bikeleasing',
    icon:  'bike',
    usage: {
      list: [
        'sachbezug'
      ],
      default: 'sachbezug'
    }
  },
  kinder: {
    title: 'Kindergartenzuschuss',
    icon:  'kindergarden',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  bgmbudget: {
    title: 'BGM Budget',
    icon:  'bgmbudget',
    usage: {
      list: [
        'barauszahlung',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  jobticket: {
    title: 'Jobticket',
    icon:  'jobticket',
    usage: {
      list: [
        'barauszahlung',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  VMA_PST: {
    title: 'Verpflegungsmehraufwand (pSt.)',
    icon:  'food',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  VMA_STSVFREI: {
    title: 'Verpflegungsmehraufwand (st- u. sv-frei)',
    icon:  'food',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  festnetz: {
    title: 'Festnetztelefon',
    icon:  'phone',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  entfernung: {
    title: 'Entfernungspauschale',
    icon:  'trips',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  personal_rabatt: {
    title: 'Personalrabatt',
    icon:  'dailydeals',
    usage: {
      list: [
        'barauszahlung'
      ],
      default: 'barauszahlung'
    }
  },
  erholungsbeihilfe: {
    title: 'Erholungsbeihilfe',
    icon:  'aid-holidays',
    usage: {
      list: [
        'barauszahlung',
        // 'valuecard',
        // 'vorteilskonto',
        // 'erholungscard',
        'maku'
      ],
      default: 'barauszahlung'
    }
  },
  dienstwagen_miete: {
    title: 'Dienstwagengarage (Eigentum)',
    icon:  'garage',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  dienstwagen_auslage: {
    title: 'Dienstwagengarage (gemietet)',
    icon:  'auto',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  internet: {
    title: 'Internetpauschale',
    icon:  'internet',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  werbeflaeche: {
    title: 'Werbeflächenanmietung',
    icon:  'advertising',
    usage: {
      list: [
        'barauszahlung',
        'valuecard',
        'vorteilskonto'
      ],
      default: 'barauszahlung'
    }
  },
  pc: {
    title: 'PC-Leasing',
    icon:  'laptop',
    usage: {
      list: [
        'sachbezug'
      ],
      default: 'sachbezug'
    }
  },
  Sonstiges: {
    title: 'Sonstiges',
    icon:  'others'
  },
  besonderer_anlass: {
    title: 'Besondere Anlässe p.a.',
    icon:  'others',
    usage: {
      list: [
        'valuecard',
        'vorteilskonto'
      ],
      default: 'valuecard'
    }
  },
  car_leasing: {
    title: 'Auto Abo',
    icon:  'auto',
  },
  BAV: {
    title: 'BAV',
    icon:  'others',
  },
  dticket: {
    title: 'DeutschlandTicket JOB',
    icon:  'coupons',
  },
  anzahl: {
    title: 'Aktive Teilnehmer',
    icon:  'document',
  }
};

export default ComponentConstants;
