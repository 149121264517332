export class DTicketOrder {

  public ticketDefaultPrice = 49;

  constructor(
    public id: string,
    public state: string,
    public price: number,
    public employerSubsidy: number,
    public employeeContribution: number,
    public flatTax: number,
    public taxPayer: string,
    public employeeId: number,
    public customerId: number,
    public createdAt: Date,
    public orderReference: string,
    public beginDate: Date | null = null,
    public endDate: Date | null = null,
    public otrsTicketNumber: string | null = null,
    public contractFileId: string | null = null,
    public mobilityBoxOrderId: string | null = null,
    public userPreferences: {isPdfTicket: boolean} | null = null,
    public canRestoreTicket: boolean = false,
    public canCancelTicket: boolean = false
  ) {
  }

  isThereATaxPayer() {
    return this.taxPayer !== 'no_tax'
  }

  getDiscount() {
    return (this.ticketDefaultPrice - this.price).toFixed(2);
  }

  canSendOrderToVesputiOnUserPreferences(): boolean {
    return !!(!this.isInvalid() && this.mobilityBoxOrderId);
  }

  isCancelAllowed() {
    return this.canCancelTicket;
  }

  isInvalid() {
    return ['cancelled', 'approver_declined', 'expired'].includes(this.state)
  }

  canEmployeeGetHisQRCode() {
    return this.state === 'activated'
  }

  static getInstanceFromVNMiddelware(data: {
    id: string,
    state: string,
    price: number,
    employer_subsidy: number,
    employee_contribution: number,
    flat_tax: number,
    tax_payer: string,
    employee_id: number,
    customer_id: number,
    order_reference: string,
    begin_date: string|null,
    end_date: string|null,
    checkout_date: string,
    otrs_ticket_number: string|null,
    contract_file_id: string|null,
    order_id: string|null,
    user_preferences: {is_pdf_ticket: boolean}|null,
    is_restore_visible: boolean,
    is_cancellation_allowed: boolean

  }) {
    const beginDate = data.begin_date ? new Date(data.begin_date) : null;
    const endDate = data.end_date ? new Date(data.end_date) : null;
    const createdAt = new Date(data.checkout_date);
    let userPreferences = null;

    if (data.user_preferences) {
      userPreferences = {
        'isPdfTicket': data.user_preferences.is_pdf_ticket
      }
    }

    return new this(
      data.id,
      data.state,
      data.price,
      data.employer_subsidy,
      data.employee_contribution,
      data.flat_tax,
      data.tax_payer,
      data.employee_id,
      data.customer_id,
      createdAt,
      data.order_reference,
      beginDate,
      endDate,
      data.otrs_ticket_number,
      data.contract_file_id,
      data.order_id,
      userPreferences,
      data.is_restore_visible,
      data.is_cancellation_allowed
    );
  }

}
