<div>
    <vn-matblur-loading></vn-matblur-loading>
    <h1 class="title">
        Kundenaufträge
    </h1>

    <vn-bonus-order-table [tableData]='(dataStream$ | async)!'
                          (tableDataChange)="onTableDataChange()">
        <form [formGroup]='filtersGroup' (keydown.enter)="$event.preventDefault()">
            <vn-input formControlName='customerId'
                      label="Kundennummer"
                      type="number"
                      placeholder='Kundennummer'
                      (keydown.enter)="loadDataStream()">
            </vn-input>

            <vn-input formControlName='customerName'
                      label="Kundenname"
                      placeholder='Kundenname'
                      (keydown.enter)="loadDataStream()">
            </vn-input>

            <vn-select formControlName='orderState'
                       label="Bestellstatus"
                       placeholder='Alle'
                       [options]='filterOptions?.orderState'
                       (keydown.enter)="loadDataStream()">
            </vn-select>

            <vn-date formControlName='orderPeriod'
                     label="Periode"
                     format="MM.YYYY"
                     placeholder="MM.JJJJ"
                     (keydown.enter)="loadDataStream()"
                     [ignoreDay]=true
                     [ignoreYear]=true>
            </vn-date>

            <div class='form-group'>
                <label for='withOnHold'>
                    mit Warten an
                </label>

                <mat-slide-toggle id="withOnHoldToggle"
                                  data-cy="vn.directBonus.backofficeAdministration.customerOrderTableHeader.switchShowFlagged"
                                  color="blue"
                                  formControlName="withOnHold"
                                  (keydown.enter)="loadDataStream()">
                </mat-slide-toggle>
            </div>

            <div class="spacer"></div>

            <button mat-icon-button 
                mat-raised-button
                data-cy="vn.directBonus.backofficeAdministration.customerOrderTableHeader.buttonApplyFilters"
                [disabled]="!canSearch"
                (click)="loadDataStream()">
                <mat-icon>filter_list</mat-icon>
            </button>

            <button mat-icon-button 
                mat-raised-button
                data-cy="vn.directBonus.backofficeAdministration.customerOrderTableHeader.buttonClearFilters"
                [disabled]="!isListFiltered"
                (click)="clearFilters()">
                <mat-icon>filter_list_off</mat-icon>
            </button>
            
        </form>

        <!-- <button mat-icon-button
                class="menu-button"
                data-cy="vn.directBonus.backofficeAdministration.customerOrderTableHeader.buttonActionsMenu"
                [matMenuTriggerFor]="menu"
                *ngIf="hasContextMenu()"
                (click)='menuClick($event)'>
            <mat-icon>
                more_vert
            </mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item
                    *ngIf="false"
                    data-cy="vn.directBonus.backofficeAdministration.customerOrderTableActionsMenu.buttonBulkInvoice"
                    (click)="bulkInvoice()">
                <mat-icon>
                    keyboard_double_arrow_down
                </mat-icon>

                <span>
                    Bulk Faktura
                </span>
            </button>

            <button mat-menu-item
                    *ngIf="false"
                    data-cy="vn.directBonus.backofficeAdministration.customerOrderTableActionsMenu.buttonBulkOrderAsPaid"
                    (click)="bulkOrderAsPaid()">
                <mat-icon>
                    keyboard_double_arrow_down
                </mat-icon>

                <span>
                    Bulk Bezahlen
                </span>
            </button>

          <button mat-menu-item
                  data-cy="vn.directBonus.backofficeAdministration.customerOrderTableActionsMenu.buttonBulkOrderAsDone"
                  (click)="bulkOrderAsDone()">
            <mat-icon>
              keyboard_double_arrow_down
            </mat-icon>

            <span>
                    Bulk Ausführen
                </span>
          </button>
        </mat-menu> -->
    </vn-bonus-order-table>
</div>
