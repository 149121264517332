interface availableCashbackComponentModel {
  bonusProjectIds: string;
  name: string;
  value: string;
}

type periodNameTypes =
  | '1 Tag'
  | '1 Woche'
  | '1 Monat'
  | '3 Monate'
  | '6 Monate'
  | '12 Monate';

type periodValueTypes =
  | 'one_day'
  | 'one_week'
  | 'one_month'
  | 'three_months'
  | 'six_months'
  | 'twelve_months';

enum bonusTypes {
  jobTicket = 'jobticket',
  kindergarten = 'kinder',
  bgm = 'bgmbudget'
}

interface invoiceValidPeriodsModel {
  name: periodNameTypes;
  value: periodValueTypes;
}

export {
  availableCashbackComponentModel,
  invoiceValidPeriodsModel,
  bonusTypes
}
