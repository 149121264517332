import confirmation_info_dialog_html from '../../../../modules/cockpit/bonus/create/confirmation_info_dialog.pug'
import transfer_info_dialog_html from '../../../../modules/cockpit/bonus/create/transfer_info_dialog.pug'
import {
  BONUS_PROJECT_TYPES,
  BONUS_PROJECT_TYPES_BY_ID,
  BONUS_PROJECT_TYPES_BY_NAME
} from "../../../../services/bonus/bonus_project_types";
import {TAX_PAYERS} from "../../../../services/bonus/tax_payer";
import {INSURANCE_PAYERS} from "../../../../services/bonus/insurance_payers";
import {BONUS_PROJECT_STATES_BY_NAME} from "../../../../services/bonus/bonus_project_states";
import {BONUS_TYPES, BONUS_TYPES_BY_ID} from "../../../../services/bonus/bonus_type";
import {BONUS_EXPIRATION_ACTIONS} from "../../../../services/bonus/bonus_expiration_action";
import {PC_RATE_TYPES} from "../../../../services/bonus/pc_rate_type";
import {DEFAULT_BONUS_PROJECT} from "../../../../services/bonus/default_bonus_project";
import { DIRECT_BONUS_PROJECT_COMPONENT_CONFIG_FIELDS } from '../../../../services/bonus/direct_bonus_project_component_config_fields';

const EXCLUDE_BUDGET_CONFIG_FIELDS = ['total', 'ag_cost_total'];

const $inject = [
  '$scope',
  'customerService',
  'lodash',
  'BonusService',
  'NotificationService',
  'authenticationService',
  '$q',
  '$timeout',
  'moment',
  'dialogService',
  'UserNotificationService',
  'SteuerklassenConstants',
  'fileService'
];
export default class CreateBonusProjectDialogController {
  constructor(
    $scope,
    customerService,
    lodash,
    BonusService,
    NotificationService,
    authenticationService,
    $q,
    $timeout,
    moment,
    dialogService,
    UserNotificationService,
    SteuerklassenConstants,
    fileService
  ) {
    Object.assign(this, {
      $scope,
      customerService,
      lodash,
      BonusService,
      NotificationService,
      authenticationService,
      $q,
      $timeout,
      moment,
      dialogService,
      UserNotificationService,
      SteuerklassenConstants,
      fileService
    });

    // defaults
    this.customer_id = this.$scope.ngDialogData.customer_id;
    this.bonus_project_id = this.$scope.ngDialogData.bonus_project_id;
    this.forms = {};
    this.models = {
      import_file: null
    };
    this.validations = {
      has_loading_error: false,
      can_publish_project: {
        overall: true
      }
    };

    this.enabled_components = new Set();
    this.BONUS_PROJECT_TYPES = BONUS_PROJECT_TYPES.filter(type => type.is_enabled);
    this.BONUS_PROJECT_TYPES_BY_NAME = BONUS_PROJECT_TYPES_BY_NAME;
    this.BONUS_PROJECT_TYPES_BY_ID = BONUS_PROJECT_TYPES_BY_ID;
    this.BONUS_EXPIRATION_ACTIONS = BONUS_EXPIRATION_ACTIONS;
    this.BONUS_TYPES = BONUS_TYPES;
    this.TAX_PAYERS = TAX_PAYERS;
    this.INSURANCE_PAYERS = INSURANCE_PAYERS;
    this.PC_RATE_TYPES = PC_RATE_TYPES;
    this.today = this.moment().utc().set({ millisecond: 0, second: 0, minute: 0, hour: 0 });
    this.BONUS_PROJECT_STATES_BY_NAME = BONUS_PROJECT_STATES_BY_NAME;
    this.OBJECT = Object;
    this.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    this.iAmAdvisor = authenticationService.iAm('advisor');
    this.loading = {
      import_file: false,
      main: true
    };
    this.promises = {};
    this.watchers = [];
    this.can_edit = true;

    this.totalBonusByEmployeeIds = {}

    // load bonus project
    if (this.bonus_project_id) {
      this.promises.bonus_project = this.BonusService.getBonusProject(this.customer_id, this.bonus_project_id).then(async bonus_project => {
        // merge new properties
        this.bonus_project = this.lodash.merge(angular.copy(DEFAULT_BONUS_PROJECT), bonus_project);
        const bonusProjectEmployeeIds = Object.keys(this.bonus_project.for_employees).map(id => parseInt(id));
        this.totalBonusByEmployeeIds = await this.BonusService.getBonusValuesByEmployeeIds(bonusProjectEmployeeIds, this.bonus_project_id);


        // 1. config_fields
        // this code is used ... it adds new data if we already store in mongo shit fuck ugly so stupid....... this is ridiculous
        let config_fields_promise = this.BonusService.getBonusProjectComponentConfig(this.bonus_project.project_type).then(default_config_fields => {
          // we are merging array of objects and therefore do not know if the change is in the array or in the objects
          default_config_fields = angular.copy(default_config_fields);
          let current_config_fields = this.bonus_project.config_fields;

          // TODO: this has to be recursive to allow of all cases

          let merged_config_fields_map_by_prop = {};
          let current_config_fields_props = Object.keys(current_config_fields);
          let default_config_fields_props = Object.keys(default_config_fields);
          let intersection_config_fields_props = this.lodash.intersection(current_config_fields_props, default_config_fields_props);
          let difference_default_current = this.lodash.difference(default_config_fields_props, current_config_fields_props);
          let difference_current_default = this.lodash.difference(current_config_fields_props, default_config_fields_props);

          // merge all differences between default and current
          difference_default_current.forEach(prop => {
            merged_config_fields_map_by_prop[prop] = default_config_fields[prop];
          });

          // merge all differences between current and default
          difference_current_default.forEach(prop => {
            merged_config_fields_map_by_prop[prop] = current_config_fields[prop];
          });

          // merge the same properties
          for (const outer_property of intersection_config_fields_props) {
            let current_config_fields_value = current_config_fields[outer_property];
            let default_config_fields_value = default_config_fields[outer_property];

            if (this.lodash.isArray(current_config_fields_value)) {
              merged_config_fields_map_by_prop[outer_property] = [];

              let current_config_fields_value_map_by_prop = current_config_fields_value.filter(val => !!val).reduce((acc, config_field, index) => {
                acc[config_field['prop'] || config_field['id'] || index] = config_field;
                return acc;
              }, {});

              let default_config_fields_value_map_by_prop = default_config_fields_value.filter(val => !!val).reduce((acc, config_field, index) => {
                acc[config_field['prop'] || config_field['id'] || index] = config_field;
                return acc;
              }, {});

              // get all the properties of the two objects
              let current_config_fields_props = Object.keys(current_config_fields_value_map_by_prop);
              let default_config_fields_props = Object.keys(default_config_fields_value_map_by_prop);
              // find the intersection and differences between the two sets of properties
              let intersection_config_fields = this.lodash.intersection(current_config_fields_props, default_config_fields_props);
              let difference_current_default = this.lodash.difference(current_config_fields_props, default_config_fields_props);
              let difference_default_current = this.lodash.difference(default_config_fields_props, current_config_fields_props);

              // merge the "INTERSECTION"
              intersection_config_fields.forEach((prop) => {
                // when done recursively check type of property as well - here I assume all are objects because I know
                let merged_config = this.lodash.merge(default_config_fields_value_map_by_prop[prop], current_config_fields_value_map_by_prop[prop]);
                merged_config_fields_map_by_prop[outer_property].push(merged_config);
              });

              // add difference current - default
              difference_current_default.forEach(([prop]) => {
                merged_config_fields_map_by_prop[outer_property].push(current_config_fields_value_map_by_prop[prop]);
              });

              // add difference default - current
              difference_default_current.forEach(prop => {
                // append any missing configuration which are missing from the current
                merged_config_fields_map_by_prop[outer_property].push(default_config_fields_value_map_by_prop[prop]);
              });
            } else if (this.lodash.isObject(current_config_fields_value)) {
              merged_config_fields_map_by_prop[outer_property] = this.lodash.merge(default_config_fields_value, current_config_fields_value);
            } else { // string, number ....
              // always take the current
              merged_config_fields_map_by_prop[outer_property] = current_config_fields_value;
            }

          }


          // let updated_config_fields = Object.values(merged_config_fields_map_by_prop);
          this.bonus_project.config_fields = merged_config_fields_map_by_prop;
        });

        return Promise.all([config_fields_promise]);
      });
    } else {
      this.bonus_project = angular.copy(DEFAULT_BONUS_PROJECT);
    }

    this.promises.bonus_projects = this.BonusService.getBonusProject(this.customer_id).then(bonus_projects => {
      this.bonus_projects = bonus_projects;
    });

    this.promises.employees = this.customerService.getEmployees(this.customer_id, new Date().getTime()).then(employees => {
      this.employees = employees;
    });

    if (this.bonus_project_id) {
      this.promises.validations = this.BonusService.canPublishProject({ bonus_project_id: this.bonus_project_id }).then((result) => {
        this.validations.can_publish_project = result;
      });
    }

    this.$q.all(this.promises)
      .then(() => {
        this.configureSachbezugComponents();
        if (!this.bonus_project_id || this.bonus_project.project_type !== BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id']) {
          // check if there is already a DirectBonusProject and don't allow if he has
          let has_direct_bonus_project = this.bonus_projects.reduce((acc, bonus_project) => {
            return acc || bonus_project.project_type === BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id'];
          }, false);
          if (has_direct_bonus_project) {
            this.BONUS_PROJECT_TYPES = this.BONUS_PROJECT_TYPES.filter(type => type.name !== BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['name']);
          }
        }
      })
      .then(() => {
        // ensure you know which components have been enabled
        Object.keys(this.bonus_project.budget_config).map((component_key) => {
          if (!EXCLUDE_BUDGET_CONFIG_FIELDS.includes(component_key)) {
            this.handleComponentEnableChange(component_key);
          }
        });

        // ensure bonus employees are correctly shown
        this.setUpEmployeesForBonus();
      })
      .then(() => {
        // get bonus project state
        let can_edit = this.bonus_project.project_state === BONUS_PROJECT_STATES_BY_NAME['draft']['id'];

        // DirectBonus and FiduciaBonus projects are editable
        if (
          (
            this.bonus_project.project_type === this.BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id'] ||
            this.bonus_project.project_type === this.BONUS_PROJECT_TYPES_BY_NAME['one_time_bonus']['id'] ||
            (this.bonus_project.project_type === this.BONUS_PROJECT_TYPES_BY_NAME['fiducia_bonus']['id'] && this.Made.user.valuenet_id === 113170)
          ) && this.bonus_project.project_state !== BONUS_PROJECT_STATES_BY_NAME['confirmed']['id']
        ) {
          can_edit = true;
        }

        this.$timeout(() => {
          this.can_edit = can_edit;
        });
      })
      .then(() => {
        let watcher = this.$scope.$watch(() => {
          return this.bonus_project.bonus_config.bonus_type;
        }, (newValue, oldValue) => {
          if (!angular.equals(newValue, oldValue)) {
            let bonus_type = BONUS_TYPES_BY_ID[newValue];

            this.$timeout(() => {

              // reset visibility for components
              let config_fields = angular.copy(this.bonus_project.config_fields);
              config_fields.budget.forEach(field_config => {
                field_config.is_visible = true;
              });

              let budget_config_by_name = config_fields.budget.reduce((acc, field_config) => {
                acc[field_config.prop] = field_config;
                return acc;
              }, {});

              // handle forbidden components by bonus_type
              if (bonus_type.components && bonus_type.components.forbidden) {
                let forbidden_components = bonus_type.components.forbidden;
                this.unableComponents(forbidden_components);

                forbidden_components.forEach(component_name => {
                  budget_config_by_name[component_name].is_visible = false;
                });
              }

              // reatach config_fields
              this.bonus_project.config_fields = config_fields;
            }, 0);
          }
        }, true);
        this.watchers.push(watcher);


        let import_file_watcher = this.$scope.$watch(() => {
          return this.models.import_file;
        }, (newValue, oldValue) => {
          if (newValue) {
            this.handleImportFile(newValue);
          }
        }, true);

        this.watchers.push(import_file_watcher);

        this.$scope.$on('$destroy', () => {
          this.watchers.forEach(watcher => {
            watcher();
          });
        });
      })
      .catch((e) => {
        this.validations.has_loading_error = true;
        console.error("Error", e);
      })
      .finally(() => {
        this.$timeout(() => {
          this.loading.main = false;
        }, 0);
      });
  }

  // async getEmployeeUsedBonus(employeeId) {
  //   this.$timeout(() => {
  //     this.loading.main = true;
  //   }, 0);
  //   const val = await this.BonusService.getComponentSum(employeeId, this.bonus_project_id)
  //   this.$timeout(() => {
  //     this.loading.main = false;
  //   }, 0);

  //   return val;
  // }

  // XXX this is ugly, but it is the only way atm to order the sachbezugcomponets that were already stored
  configureSachbezugComponents() {
    const data = [...this.bonus_project.config_fields.budget]
  // Define the desired order for 'sachbezug' related objects
    const sachbezugOrder = ['sachbezug', 'sachbezug_profit', 'sachbezug_pluxee'];
  
  // Custom sort function
    const customSort = (a, b) => {
        const aIndex = sachbezugOrder.indexOf(a.prop);
        const bIndex = sachbezugOrder.indexOf(b.prop);
    
        // If both a and b are in the sachbezugOrder, sort them according to their order in sachbezugOrder
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
        }
    
        // If only a is in sachbezugOrder, it should come first
        if (aIndex !== -1) {
            return -1;
        }
    
        // If only b is in sachbezugOrder, it should come first
        if (bIndex !== -1) {
            return 1;
        }
    
        // If neither are in sachbezugOrder, keep their original order
        return 0;
    };
  
  // Sort the array using the custom sort function
    data.sort(customSort);
    this.bonus_project.config_fields.budget = data;
    this.bonus_project.config_fields.budget.forEach(field_config => {
      
      if (field_config.label === 'Sachbezug') {
        field_config.label = 'Sachbezug Vorteilskonto'
      }
      if (field_config.prop.includes('sachbezug')) {
        field_config.settings.forEach(metadata => {
          if (metadata.label === 'Standardwert') {
            metadata.label = 'Mindestwert';
          }
        })
      }
    })
  }

  canSaveProject() {
    let isSuperUser = this.iAmSpecialAdvisor || this.iAmAdvisor;
    let isBaseFormValid = this.lodash.get(this.forms, 'base_info.$valid', false);
    let isUsersFormValid = this.lodash.get(this.forms, 'users.$valid', true);
    let isComponentsFormValid = this.lodash.get(this.forms, 'components.$valid', false);
    let hasSelectedComponent = this.enabled_components && this.enabled_components.size > 0;
    let hasSelectedUsers = this.lodash.get(this, 'employeesConfig.isRequiredOnProjectCreation', false) ?
      Object.values(this.bonuses_for_employees || {}).reduce(
        (acc, employee_config) => employee_config.to_get || acc, false
      ) : true;

    return isSuperUser &&
      isBaseFormValid &&
      isUsersFormValid &&
      isComponentsFormValid &&
      hasSelectedComponent &&
      hasSelectedUsers;
  }

  canPublishProject() {
    let canSaveProject = this.canSaveProject();
    let canPublishToday = this.toPublishToday();
    return canSaveProject && canPublishToday && this.validations.can_publish_project.overall;
  }

  async handleImportFile(file) {
    this.$timeout(() => {
      this.loading.import_file = true;
    });

    let file_data = await this.fileService.readFile(file, 'data_array');
    if (!(file_data && file_data.length > 0)) {
      return;
    }

    // parse the file
    let headers = file_data.shift();
    // validate headers
    let required_headers = new Set(['id', 'personalnummer', 'custId', 'name', 'vorname', 'bonus', 'kist', 'stkl']);
    for (let header_index in headers) {
      required_headers.delete(headers[header_index]);
    }

    if (required_headers.size > 0) {
      return;
    }

    let entries = [];
    for (let entry_data of file_data) {
      let entry = {};
      for (let [header_index, header] of headers.entries()) {
        entry[header] = entry_data[header_index];
      }

      // Checking if names not in english then add english keys in the obj
      entry['firstname'] = entry['firstname'] || entry['name'];
      entry['lastname'] = entry['lastname'] || entry['vorname'];

      entries.push(entry);
    }

    for (let employee_data of entries) {
      let employee_id = employee_data['id'];

      // Ignoring that employees who not belong to this customer
      if (this.customer_id !== parseInt(employee_data['custId']))
        continue;

      this.bonuses_for_employees[employee_id] = this.lodash.merge(this.bonuses_for_employees[employee_id], {
        to_get: true,
        bonus_amount: parseFloat(employee_data['bonus']),
        is_church_tax_enabled: employee_data['kist'] === '1',
        steuer_klasse: parseInt(employee_data['stkl'])
      });

      this.handleEmployeeBonusToggle(employee_id);
    }

    this.$timeout(() => {
      this.loading.import_file = false;
    });
  }

  /**
   * Not needed however it is nice :D
   *
   * Example usage
   * {
          label : 'Decision date',
          type: 'datetime',
          key : 'user_decision_date',
          parent: '.configuration.dates',
          id: ERHOLUNGSBEIHILFE_OPTIONS.length + 2,
          conditions: [
            { id: 0, type: 'variable_check', rules: {bonus_project_type_id: BONUS_PROJECT_TYPES_BY_NAME['fiducia_bonus']['id']} }
          ]
        }
   * */
  validateConditions(conditions) {
    let are_conditions_valid = true;
    if (!conditions) {
      return are_conditions_valid;
    }

    for (let condition of conditions) {
      if (condition.type === 'variable_check') {
        let variable_check_valid = true;
        for (let [variable_name, expected_value] of Object.entries(condition.rules)) {
          if (variable_name === 'bonus_project_type_id') {
            let is_as_expected = this.bonus_project.project_type === expected_value;
            variable_check_valid = variable_check_valid && is_as_expected;
          }

          if (!variable_check_valid) {
            break;
          }
        }
        are_conditions_valid = are_conditions_valid && variable_check_valid;
      }

      if (!are_conditions_valid) {
        break;
      }
    }

    return are_conditions_valid;
  }

  async updateBonusProjectNotifications() {
    this.$timeout(() => {
      this.loading.notifications = true;
    });

    let is_updated = await this.BonusService.updateBonusProjectNotifications(this.bonus_project);
    if (is_updated) {
      this.NotificationService.message('Das Bonusprojekt wurde gespeichert');
    } else {
      this.NotificationService.error('Speichern fehlgeschlagen');
    }

    this.$scope.closeThisDialog();

    this.$timeout(() => {
      this.loading.notifications = false;
    });
  }

  openConfirmationDialog() {
    let data = {
      bonus_project: this.bonus_project
    };

    return this.dialogService.ngDialog.openConfirm({
      template: confirmation_info_dialog_html(),
      plain: true,
      controller: 'confirmationInfoDialogController',
      controllerAs: '$ctrl',
      data: data,
      width: 1000,
      closeByDocument: false,
      closeByEscape: true,
      closeByNavigation: true
    });
  }

  toPublishToday() {
    if (this.bonus_project.project_type === this.BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id'] || !this.bonus_project.dates.bonus_publish_date) {
      return true;
    } else if (!this.bonus_project.dates.bonus_publish_date) {
      return true;
    } else {
      return this.moment.utc(this.bonus_project.dates.bonus_publish_date).isSame(this.moment.utc(this.today));
    }
  }

  getDate(instance) {
    return this.BonusService.getDate(instance);
  }

  getDateConfigSettings(root, path, type) {
    // get vnMinDate or VNMaxDate settings
    if (type === 'date') {
      if (this.bonus_project.project_state === this.BONUS_PROJECT_STATES_BY_NAME['published']['id']) {
        return null;
      } else {
        let config = this.lodash.get(root, path, null);
        if (config) {
          let date = this.lodash.get(this, config);
          if (this.moment.isMoment(date) || this.moment.isDate(date)) {
            return date.toString();
          } else if (date === '') {
            return date;
          }
        }
      }
    }
  }

  unableComponent(component_name) {
    this.enabled_components.delete(component_name);
    this.bonus_project.budget_config[component_name].enabled = false;
  }

  enableComponent(component_name) {
    this.enabled_components.add(component_name);
    this.bonus_project.budget_config[component_name].enabled = true;
  }

  handleComponentEnableChange(component_name) {
    let budget_config = this.bonus_project.budget_config[component_name];
    if (budget_config.enabled) {
      // enable component
      this.enableComponent(component_name);

      // run on enable actions
      if (budget_config.on_enable) {
        if(angular.isArray(budget_config.on_enable.disable)) {
          this.unableComponents(budget_config.on_enable.disable)
        } else if (budget_config.on_enable.disable) {
          let to_disable_component_name = budget_config.on_enable.disable;
          this.unableComponent(to_disable_component_name);
        }
      }
    } else {
      this.enabled_components.delete(component_name);
    }
  }

  unableComponents(component_names) {
    component_names.forEach(component_name => {
      this.unableComponent(component_name);
    });
  }

  selectedBonusProjectType() {
    delete this.bonus_project.config_fields;

    this.BonusService.getBonusProjectComponentConfig(this.bonus_project.project_type).then((config_fields) => {
      // do defaults
      config_fields.metadata.forEach(metadata_config_field => {
        if (metadata_config_field.value) {
          if (metadata_config_field.value.default) {
            this.lodash.get(this.bonus_project, (metadata_config_field.parent))[metadata_config_field.key] = metadata_config_field.value.default;
          }
        }
      });

      // deactivate forbidden components
      let allowed_fields = config_fields.budget.map((field_config) => field_config.prop);
      let components_to_deactivate = [];
      Object.keys(this.bonus_project.budget_config).forEach((component_name) => {
        if (!EXCLUDE_BUDGET_CONFIG_FIELDS.includes(component_name) && !allowed_fields.includes(component_name)) {
          components_to_deactivate.push(component_name);
        }
      });
      this.unableComponents(components_to_deactivate);

      // set up employees config
      this.setUpEmployeesForBonus();


      // tell the UI
      this.$timeout(() => {
        this.bonus_project.config_fields = config_fields;
      }, 0);
    });
  }

  // hacky , but everything is ugly here , some workaround
  // if something determined from outside
  canEditConfigKeyCustomCondition(field, params = null) {
    if (!field['parent']) {
      return false;
    }
    if (field['parent'] === 'project_approved') {
      return this.iAmSpecialAdvisor;
    }
  }

  canEditConfigKey(config_key_config,) {
    let is_config_key_editable = true;
    let editable_only_in_state_id = this.lodash.get(config_key_config, 'settings.editable_only_in_state_id');


    if (!angular.isUndefined(editable_only_in_state_id) && editable_only_in_state_id !== this.bonus_project.project_state) {
      is_config_key_editable = false;
    }

    let setting_is_editable = this.lodash.get(config_key_config, 'settings.is_editable', true);
    if(angular.isFunction(setting_is_editable)) {
      return setting_is_editable(this.canEditConfigKeyCustomCondition(config_key_config, {}));
    }

    const isVisible = this.lodash.get(config_key_config, 'settings.is_visible');
    if(angular.isFunction(isVisible)) {
      const bindTo = this.lodash.get(config_key_config, 'settings.visible_by');
      return this.lodash.get(this.bonus_project, bindTo);
    }

    is_config_key_editable = is_config_key_editable && this.can_edit && setting_is_editable;
    return is_config_key_editable;
  }

  isEmployeeInformationRequired(employee_id) {
    let is_employee_turned_on = !!this.bonuses_for_employees[employee_id].to_get;
    let is_extra_info_required = this.employeesConfig.isExtraInfoRequired;
    return is_employee_turned_on && is_extra_info_required;
  }

  setUpEmployeesForBonus() {
    if (this.lodash.get(this.bonus_project, 'project_type', false) === false) {
      return
    }

    this.employeesConfig = this.BonusService.getBonusProjectEmployeesConfig(this.bonus_project.project_type);

    if (this.employeesConfig.isRequiredOnProjectCreation) {
      this.bonuses_for_employees = {};
      this.employees.forEach((employee) => {
        let already_assigned_bonus = this.bonus_project.for_employees[employee.id];

        this.bonuses_for_employees[employee.id] = {
          id: employee.id,
          firstname: employee.name,
          lastname: employee.vorname,
          personal_number: employee.personalnummer,
          bonus_amount: already_assigned_bonus ? already_assigned_bonus.bonus.amount : 0,
          to_get: !!already_assigned_bonus,
          is_church_tax_enabled: this.lodash.get(already_assigned_bonus, 'employee_configuration.is_church_tax_enabled', false),
          steuer_klasse: this.lodash.get(already_assigned_bonus, 'employee_configuration.steuer_klasse', undefined)
        };

        this.handleEmployeeBonusToggle(employee.id);
      });
    } else {
      // we don't give bonuses
      this.bonuses_for_employees = undefined;
    }
  }

  handleEmployeeBonusToggle(employee_id) {
    if (this.bonuses_for_employees[employee_id] && this.bonuses_for_employees[employee_id].to_get) {
      this.bonus_project.for_employees[employee_id] = this.BonusService.getEmployeeBonusConfig(employee_id, this.bonuses_for_employees[employee_id]);
    } else {
      delete this.bonus_project.for_employees[employee_id];
    }
  }

  handleEmployeeConfigurationChange(employee_id) {
    this.$timeout(() => {
      this.bonus_project.for_employees[employee_id] = this.BonusService.getEmployeeBonusConfig(employee_id, this.bonuses_for_employees[employee_id]);
    });

    this.canPublishProject();
  }

  // handleEmployeeBonusValueChange(employee_id){
  //   this.bonus_project.for_employees[employee_id].bonus.amount = this.bonuses_for_employees[employee_id].bonus_amount;
  // }


  revoke() {
    this.BonusService.revokeBonusProject(this.customer_id, this.bonus_project).then((result) => {
      this.NotificationService.message('Aktivierung wurde zurückgenommen');
      this.$scope.closeThisDialog();
    }).catch((err) => {
      this.NotificationService.error('Die Änderung konnte nicht gespeichert werden');
    });
  }

  async save() {


    let is_old_bonus_project = this.bonus_project['_id'];

    // https://vsp.atlassian.net/browse/VN-4341
    // this is hacky ... but we have to invest time to refactor the template and BonusService constants ...
    if (this.bonus_project.approvals_config.automatic_order_confirmation && !this.bonus_project.approvals_config.automatic_order_confirmation_day) {
      alert('Es wird eine Eingabe benötigt');
      return;
    }

    if (this.bonus_project.approvals_config.automatic_order_confirmation_day) {
      this.bonus_project.approvals_config.automatic_order_confirmation_day = parseInt(this.bonus_project.approvals_config.automatic_order_confirmation_day)
      if (this.bonus_project.approvals_config.automatic_order_confirmation_day < 1 || this.bonus_project.approvals_config.automatic_order_confirmation_day > 31) {
        alert('Geben Sie einen Tag zwischen');
        return;
      }
    } else {
      this.bonus_project.approvals_config.automatic_order_confirmation_day = null;
    }

    if (this.bonus_project.approvals_config.order_confirmation_reminder_day) {
      this.bonus_project.approvals_config.order_confirmation_reminder_day = parseInt(this.bonus_project.approvals_config.order_confirmation_reminder_day)
      if (this.bonus_project.approvals_config.order_confirmation_reminder_day < 1 || this.bonus_project.approvals_config.order_confirmation_reminder_day > 31) {
        alert('Geben Sie einen Tag zwischen');
        return;
      }
    } else {
      this.bonus_project.approvals_config.order_confirmation_reminder_day = null;
    }

    if (this.bonus_project.approvals_config.order_confirmation_reminder_day > this.bonus_project.approvals_config.approval_deadline_month_day) {
      alert(`Das Erinnerungsdatum muss vor dem ${this.bonus_project.approvals_config.approval_deadline_month_day} liegen`);
      return;
    }

    // console.log("this.bonus_project.approvals_config", this.bonus_project.approvals_config)
    // return;

    this.$timeout(() => {
      this.loading.saving = true;
    });

    try {
      if (is_old_bonus_project) {
        let is_published = this.bonus_project.project_state === this.BONUS_PROJECT_STATES_BY_NAME['published']['id'];

        if (this.bonus_project.project_type === this.BONUS_PROJECT_TYPES_BY_NAME['fiducia_bonus']['id']) {
          delete this.bonus_project.for_employees;
        }

        await this.BonusService.updateBonusProject(this.bonus_project, is_published);
      } else {
        await this.BonusService.createBonusProject(this.customer_id, this.bonus_project);
      }

      this.NotificationService.message('Das Bonusprojekt wurde gespeichert');
      // close create dialog
      this.$scope.closeThisDialog();

    } catch (e) {
      this.NotificationService.error('Speichern fehlgeschlagen');
    }

    this.$timeout(() => {
      this.loading.saving = false;
    });
  }

  confirm() {
    if (!this.canSaveProject()) {
      return
    }

    this.$timeout(() => {
      this.loading.confirm = true;
    });

    let operation;
    if (this.bonus_project.project_type === this.BONUS_PROJECT_TYPES_BY_NAME['direct_bonus']['id']) {
      let is_old_bonus_project = this.bonus_project['_id'];
      if (is_old_bonus_project) {
        operation = this.BonusService.publishBonusProject(this.customer_id, is_old_bonus_project).then(()=> {
          this.$scope.closeThisDialog();
        });
      } else {
        operation = this.BonusService.createBonusProject(this.customer_id, this.bonus_project).then((createResult)=>{
          return this.BonusService.publishBonusProject(this.customer_id, createResult.bonus_project_id);
        }).then(()=>{
          this.$scope.closeThisDialog();
        });
      }
    } else {
      operation = this.openConfirmationDialog().then((toConfirm) => {
        if (toConfirm) {
          return this.BonusService.confirmBonusProject(this.customer_id, this.bonus_project).then((result) => {
            this.NotificationService.message('Das Bonusprojekt wurde aktiviert');
            // close create dialog
            this.$scope.closeThisDialog();
          }).catch((err) => {
            this.NotificationService.error('Aktivierung fehlgeschlagen');
          });
        }
      });
    }
    operation.finally(() => {
      this.$timeout(() => {
        this.loading.confirm = false;
      });
    });
  }

  async publish() {

    if (!this.canPublishProject()) {
      this.NotificationService.error('Can not publish this project');
      return;
    }

    this.$timeout(() => {
      this.loading.publish = true;
    });

    // if transfer is ON show dialog before publishing
    let operation = this.$q.when(true);
    if (this.bonus_project.transfer.is_enabled) {
      operation = this.dialogService.ngDialog.openConfirm({
        template: transfer_info_dialog_html(),
        plain: true,
        controller: 'TransferInfoDialogController',
        controllerAs: '$ctrl',
        data: {
          bonus_project: this.bonus_project
        },
        width: 1000,
        closeByDocument: false,
        closeByEscape: true,
        closeByNavigation: true
      });
    }

    operation = operation.then(async (confirmation_result) => {
      if (confirmation_result) {
        await this.BonusService.publishBonusProject(this.customer_id, this.bonus_project);
        this.NotificationService.message('Bonus Projekt wurde veröffentlicht');
        this.$scope.closeThisDialog();
      }
    });

    operation.catch((err) => {
      this.NotificationService.error('Veröffentlichung fehlgeschlagen');
    });

    operation.finally(() => {
      this.$timeout(() => {
        this.loading.publish = false;
      });
    });
  }
}
CreateBonusProjectDialogController.$inject = $inject;
