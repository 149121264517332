const $inject = [
  '$scope',
  '$state',
  '$timeout',
  'customerService',
  'CarLeasingApiService',
  'lodash',
  'NotificationService',
  'authenticationService',
  'CarAdvantageFuelMapper',
]

export default class CockpitCarLeasingController {
  constructor(
    $scope,
    $state,
    $timeout,
    customerService,
    CarLeasingApiService,
    lodash,
    NotificationService,
    authenticationService,
    CarAdvantageFuelMapper,
) {
    Object.assign(this, {
      $scope,
      $state,
      $timeout,
      customerService,
      lodash,
      NotificationService,
      authenticationService,
      CarAdvantageFuelMapper,
    });
    this.carLeasingApi = CarLeasingApiService;

    this.customerId = this.$state.params.customerId;
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.iAmAdvisor = this.authenticationService.iAm('gadvisor') || this.authenticationService.iAm('advisor');

    this.carLeasingConfigurationForm = {};

    this.exchangeTypes = [
      {
        id: 'brutto',
        name: 'Brutto',
      },
      {
        id: 'netto',
        name: 'Netto',
      }
    ];

    this.fleetManagement = [
      {
        name: 'kunde',
        value: 'Kunde'
      },
      {
        name: 'sl',
        value: 'über SL'
      },
      {
        name: 'valuenet',
        value: 'über ValueNet'
      }
    ];

    this.consumption = [
      {
        name: 'kein',
        value: 'Kein'
      },
      {
        name: 'invoice',
        value: 'Belegeinreichung'
      },
      {
        name: 'card',
        value: 'Tankkarte'
      }
    ];

    this.$scope.avEngines = {
      selected: [],
      options: [
        {
          id: 'Elektro',
          name: 'Elektro',
        },
        {
          id: 'Benzin (mild-hybrid)',
          name: 'Benzin (mild-hybrid)',
        },
        {
          id: 'Diesel (mild-hybrid)',
          name: 'Diesel (mild-hybrid)',
        },
        {
          id: 'Plug-In-Hybrid',
          name: 'Plug-In-Hybrid',
        },
        {
          id: 'Diesel',
          name: 'Diesel',
        },
        {
          id: 'Benzin',
          name: 'Benzin',
        },
        {
          id: 'Hybrid-Benzin',
          name: 'Hybrid-Benzin'
        }
      ],
    };

    this.$scope.avDuration = {
      selected: [],
      options: [1, 6, 9, 12, 13, 18, 24],
    };

    this.electricChargerAvailability = [
      {
        name: 'Vorhanden',
        value: 'charger_at_company'
      },
      {
        name: 'Nicht vorhanden',
        value: 'no_charger_at_company'
      }
    ];

    this.fuelBonusTypes = [
      {
        name: 'Fixed',
        value: 'fixed'
      },
      {
        name: 'Percentage',
        value: 'percentage'
      }
    ];


    this.loading = false;
  }

  async $onInit() {
    this.$timeout(()=>{
      this.loading = true;
    });

    this.configuration = await this.carLeasingApi.getCarLeasingCustomerConfig(this.customerId, true) ;

    if (!this.configuration) {
      this.configuration = await this.carLeasingApi.getDefaultCustomerConfig();
    }

    this.customer = await this.customerService.get(this.customerId);

    this.$scope.avDuration.selected = this.lodash.get(this.configuration, 'duration', []);
    this.$scope.avEngines.selected = this.$scope
      .avEngines
      .options
      .filter(
        engine => this.lodash.get(this.configuration, 'engines', []).indexOf(engine.id) !== -1
      );

    this.carLeasingConfigurationModel = {
      contractNumber: this.lodash.get(this.configuration, 'contract_number', null),
      engines: this.$scope.avEngines.selected,
      duration: this.$scope.avDuration.selected,
      fleet: this.lodash.get(this.configuration, 'fleet', null),
      fleetFee: this.lodash.get(this.configuration, 'fleet_fee', 0),
      maxLeasingsPerEmployee: this.lodash.get(this.configuration, 'max_leasings_per_employee', 1),
      employerPayment: this.lodash.get(this.configuration, 'employer_payment', 0),
      employerPaymentLimitation: this.lodash.get(this.configuration, 'employer_payment_limitation', false),
      exchangeType: this.lodash.get(this.configuration, 'exchange_type', this.exchangeTypes[0].id),
      consumptionIncluded: this.lodash.get(this.configuration, 'consumption_included', this.consumption[0].name),
      maxExchange: this.lodash.get(this.configuration, 'max_exchange', 0),
      fee: this.lodash.get(this.configuration, 'fee', 0),
      bringBackFee: this.lodash.get(this.configuration, 'bring_back_fee', 0),
      VNFlatTax: this.lodash.get(this.configuration, 'vn_flat_tax', 0),
      electricChargerAvailability: this.lodash.get(this.configuration, 'electric_charger_availability', true),
      carFuelBonusConfig: this.lodash.get(this.configuration, 'car_fuel_bonus_config'),
      baseForCarFlatTaxPercentagePriceLimit: this.lodash.get(this.configuration, 'base_for_car_flat_tax_percentage_price_limit', 60000)
    };

    this.watches = [];

    let watch1 = this.$scope.$watch('avDuration.selected', (newValue, oldValue, scope) => {
      this.carLeasingConfigurationModel.duration = newValue.length ? newValue : '';
    });
    this.watches.push(watch1);

    let watch2 = this.$scope.$watch('avEngines.selected', (newValue, oldValue, scope) => {
      this.carLeasingConfigurationModel.engines = newValue.length ? newValue.map(engine => engine.id) : '';
    });
    this.watches.push(watch2);

    await this.getCarLeasingAllowedFuelAmounts();

    this.$timeout(()=>{
      this.loading = false;
    })
  }

  $onDestroy() {
    this.watches.forEach(watch => watch());
  }

  canEdit() {
    return this.iAmSpecialAdvisor || this.iAmAdvisor;
  }

  canSave() {
    return this.canEdit() && !this.loading && this.carLeasingConfigurationForm.$valid && ( this.customer && !this.customer.fixated );
  }

  async save() {
    this.loading = true;
    
    let res = await this.carLeasingApi.setCustomerConfig(this.customerId, {
        contract_number: this.carLeasingConfigurationModel.contractNumber,
        engines: this.carLeasingConfigurationModel.engines,
        duration: this.carLeasingConfigurationModel.duration,
        max_leasings_per_employee: this.carLeasingConfigurationModel.maxLeasingsPerEmployee,
        employer_payment: this.carLeasingConfigurationModel.employerPayment,
        employer_payment_limitation: this.carLeasingConfigurationModel.employerPaymentLimitation,
        exchange_type: this.carLeasingConfigurationModel.exchangeType,
        max_exchange: this.carLeasingConfigurationModel.maxExchange,
        fleet: this.carLeasingConfigurationModel.fleet,
        fleet_fee: this.carLeasingConfigurationModel.fleetFee,
        consumption_included: this.carLeasingConfigurationModel.consumptionIncluded,
        fee: this.carLeasingConfigurationModel.fee,
        bring_back_fee: this.carLeasingConfigurationModel.bringBackFee,
        vn_flat_tax: this.carLeasingConfigurationModel.VNFlatTax,
        electric_charger_availability: this.carLeasingConfigurationModel.electricChargerAvailability,
        car_fuel_bonus_config: this.carLeasingConfigurationModel.carFuelBonusConfig,
        base_for_car_flat_tax_percentage_price_limit: this.carLeasingConfigurationModel.baseForCarFlatTaxPercentagePriceLimit
      }
    );

    this.NotificationService.message('Die Änderungen wurden gespeichert.');

    this.$timeout(() => {
      this.loading = false;
    });
  }

  isBonusTypeFixed() {
    if(this.carLeasingConfigurationModel) {
      const bonusType = this.carLeasingConfigurationModel.carFuelBonusConfig.car_fuel_bonus_type;
      return bonusType == 'fixed';
    }
  }

  isCompanyChargerAvailable() {
    return this.carLeasingConfigurationModel.electricChargerAvailability !== 'no_charger_at_company';
  }

  getMaxLegalBonusAmount(type) {
    if(!this.allowedFuelAmounts) {
      return 0
    }
    const isCompanyChargerAvailable = this.isCompanyChargerAvailable();
    const maxAmount = this.allowedFuelAmounts[type][isCompanyChargerAvailable];
    return maxAmount;
  }

  async getCarLeasingAllowedFuelAmounts() {
    const advantageConfig = await this.carLeasingApi.getCarLeasingAdvantageConfig();
    this.allowedFuelAmounts = this.CarAdvantageFuelMapper.fromApi(advantageConfig);
  }

}

CockpitCarLeasingController.$inject = $inject;
