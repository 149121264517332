import { PCComponentModel } from '../../../models/pc-component-model';
import { ComponentModel } from "../../../models/component-model";

import { CarLeasingDashboard } from '../../../../app/car-leasing/models/car-leasing-dashboard.model'

const $inject = [
  '$q',
  '$scope',
  '$state',
  '$stateParams',
  'Made',
  'vbmData',
  'SchemaService',
  'componentsService',
  'bausteineQuiz',
  'basicInfoQuiz', // not used anymore
  'bavQuiz',
  'advisorService',
  'paymentService',
  'dialogService',
  'employeeService',
  'PlausibilityService',
  'debounceFactory',
  'neoModifierService',
  'customerService',
  'NotificationService',
  'CheckoutService',
  'authenticationService',
  'vbmService',
  'userService',
  'BonusService',
  '$timeout',
  'NeoComponentsService',
  'CarLeasingApiService',
  'VSP_CONSTANTS',
  'administrationService'
];
export class VerguetungController {
  constructor($q,
    $scope,
    $state,
    $stateParams,
    Made,
    vbmData,
    SchemaService,
    componentsService,
    bausteineQuiz,
    basicInfoQuiz, // not used anymore
    bavQuiz,
    advisorService,
    paymentService,
    dialogService,
    employeeService,
    PlausibilityService,
    debounceFactory,
    neoModifierService,
    customerService,
    NotificationService,
    CheckoutService,
    authenticationService,
    vbmService,
    userService,
    BonusService,
    $timeout,
    NeoComponentsService,
    CarLeasingApiService,
    VSP_CONSTANTS,
    administrationService
  ) {
    Object.assign(this, {
      $q,
      $scope,
      $state,
      $stateParams,
      Made,
      vbmData,
      SchemaService,
      componentsService,
      bausteineQuiz,
      basicInfoQuiz,
      bavQuiz,
      advisorService,
      paymentService,
      dialogService,
      employeeService,
      PlausibilityService,
      debounceFactory,
      neoModifierService,
      customerService,
      NotificationService,
      CheckoutService,
      authenticationService,
      vbmService,
      userService,
      BonusService,
      $timeout,
      NeoComponentsService,
      CarLeasingApiService,
      VSP_CONSTANTS,
      administrationService
    });

    this.init().then(() => {
      this.initNeo();
    });
  }

  async initCheckoutBasket() {
    this.$timeout(() => {
      this.loading.components = true;
    });

    this.checkout_basket = await this.NeoComponentsService.getCheckoutBasket({ employee_id: this.employee_id });

    this.$timeout(() => {
      this.loading.components = false;
    });
  }

  async getPCComponents() {
    this.$timeout(() => {
      this.loading.components = true;
    });


    // TODO: use BE calls getAvailable
    let pc_components = await this.NeoComponentsService.getUsedPCComponent({
      employee_id: this.employee_id,
    });

    // create new if needed
    if (this.$scope.customerConfiguration.neo_config.pc.max_number_of_contracts > pc_components.length) {
      let has_available_pc = pc_components.find(pc_component => pc_component.is_available);
      if (!has_available_pc) {
        let pc_component = this.NeoComponentsService.createPCComponent({
          employee_id: this.employee_id,
          neo_project: this.neoProject,
          customer_configuration: this.$scope.customerConfiguration
        });
        pc_components.push(pc_component);
      }
    }

    this.$timeout(() => {
      this.loading.components = false;
      this.pc_components = pc_components;
    });
  }

  isComponentUsed(component) {
    return component.is_active;
  }

  init() {

    this.loading = {
      components: false
    };

    this.$scope.parent_state = this.$state.params.parent_state;
    this.notMe = this.Made.user.valuenet_id != this.vbmData.employeeId;
    this.iAmProxy = this.userService.iAmProxy();

    this.$scope.employeeId = this.vbmData.employeeId = this.Made.user.valuenet_id;
    this.employee_id = this.$scope.employeeId;

    this.$scope.customerService = this.customerService;

    this.$scope.componentsService = this.componentsService;
    this.$scope.bausteineQuiz = this.bausteineQuiz.get();
    this.$scope.basicInfoQuiz = this.basicInfoQuiz;
    this.$scope.loadingBausteine = true;
    this.$scope.quiz = true;

    this.$scope.iAmAdvisor = this.authenticationService.iAm('advisor');
    this.$scope.iAmGAdvisor = this.authenticationService.iAm('gadvisor');
    this.$scope.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');

    this.$scope.transition = (component) => {
      // if old way
      if (angular.isString(component)) {
        this.$state.go(`inApp.neo.side.module${component}`);
      } else if (component instanceof ComponentModel) {
        this.$state.go(`inApp.neo.side.moduleNew${component.id}`, { neo_component: component });
      }
    };

    this.transition = this.$scope.transition;

    this.$scope.basicInfoQuiz.update = this.update;
    this.$scope._extras = [
      {
        id: 'bav',
        title: 'Betriebliche Altersvorsorge'
      }
    ];

    return this.customerService.getCustomerId().then(customer_id => {
      this.customer_id = customer_id;
    });
  }

  initNeo() {

    this.customerService
      .isInfomode()
      .then(infomode => this.$scope.infomode = infomode);

    this.CheckoutService
      .checkSession()
      .then(checkoutSession => {
        this.inCheckout = checkoutSession;
        if (checkoutSession) {
          this.dialogService.resumeCheckout();
        }
      });

    this.userService.getBirthdateStatus()
      .then(state => {
        if (!state) {
          this.$scope.quiz = false;
          this.dialogService.employeeBirthdate(this.$scope.employeeId);
        }
      });

    this.vbmData.trigger = this.trigger;
    this.$scope.setup = angular.noop;

    if ('bav' === this.$state.params.bonus) {
      this.bavQuiz.steps[0].title = 'Wie viel wollen Sie monatlich in Ihre betriebliche Altersvorsorge investieren?';
      delete this.bavQuiz.bkv;
      this.$scope.extras = this.$scope._extras;
      this.$state.params.bonus = '';

      this.$scope.bavQuiz = this.bavQuiz.get();

      this.bavQuiz.exit = () => {
        return this.vbmData
          .save(this.vbmData.employeeId)
          .then(() => {
          }, error => this.NotificationService.default.checkoutSession(error));
      };

      this.$scope.setup = () => {
        this.bavQuiz.model = this.vbmData.employees[this.vbmData.employeeId].data.insurances;

        this.SchemaService
          .get('rpc://vbm/neo/calculate')
          .then(function (schema) {

            this.componentsService.getMaxValues('', this.$scope.employee.data.parameters.jahr).then(values => {
              this.bavQuiz.steps[0].items[0].title = 'Bitte wählen Sie einen Betrag zwischen 0 und ' + values.bav + ' Euro';
            });

            this.bavQuiz.steps[0].schema = schema.insurances;
            this.bavQuiz.start();
          });
      };

    }

    let when = true;

    if (
      this.vbmData.employees[this.vbmData.employeeId] &&
      this.vbmData.employees[this.vbmData.employeeId].result &&
      this.vbmData.employees[this.vbmData.employeeId].result.promise
    ) {
      when = this.vbmData.employees[this.vbmData.employeeId].result.promise;
    }

    this.$q
      .when(when)
      .finally(() => this.getData())
      .then(async () => {
        this.neoProject = await this.$scope.customerService.getNeoProjectByEmployeeId(this.vbmData.employeeId);
        this.$scope.customerConfiguration = await this.customerService.getConfiguration(this.vbmData.employees[this.vbmData.employeeId].data.person.customer_id);
      })
      .then(async () => {
        let currentYear = new Date().getFullYear();
        let evBeginMinDate = await this.CheckoutService.getEvBeginMinDate();
        let evBeginMinDateYear = evBeginMinDate.getFullYear();

        let is_version_valid = angular.equals(this.$scope.employee.data.parameters.version, currentYear); //should be the current year
        let is_year_valid = angular.equals(this.$scope.employee.data.parameters.jahr, evBeginMinDateYear); //this has to be the begin date

        if (!(is_version_valid && is_year_valid)) {
          this.$scope.employee.data.parameters.version = currentYear;
          this.$scope.employee.data.parameters.jahr = evBeginMinDate.getFullYear();

          return this.applyNewConfig().then(() => {
            this.NotificationService.message('Wir haben die letzte Berechnung auf das aktuelle Steuerjahr angepasst.');
          });
        }
      })
      .then(async () => {
        if (this.$scope.employee.data.insurances.bav.beitrag) {
          this.$scope.extras = this.$scope._extras;
        }


        this.$scope.setup();

        this.carLeasingDashboardComponents = [];
        let hasCustomerActivatedCarLeasing = !this.componentsService.isComponentDisabledForSelfservice(this.neoProject, 'car_leasing');
        if (hasCustomerActivatedCarLeasing) {
          let carLeasings = await this.CarLeasingApiService.getEmployeeBaskets(this.$scope.employeeId);
          this.carLeasingDashboardComponents = carLeasings.map(() => new CarLeasingDashboard(true));
        }

        let hasCustomerActivatedPC = await this.NeoComponentsService.hasCustomerActivatedPC({ employee_id: this.vbmData.employeeId });
        if (hasCustomerActivatedPC) {
          await this.initCheckoutBasket();
          await this.getPCComponents();
          let optimisation = await this.administrationService.getEmployeeOptimization(this.$scope.employeeId);
          let hasPC = optimisation && !!optimisation.components.find(el => el.name === 'pc');
          if ((this.notMe || this.iAmProxy) && hasPC) {
            this.dialogService
              .frageDialog('ACHTUNG: Neues PC Leasing ist aktiv - Baustein muss entfernt werden und Vertragsdokumente aktualisiert werden.', '')();
          }
        }

        // this.dTicketComponent = [];
        // this.hasCustomerActivatedDTicket = !this.componentsService.isComponentDisabledForSelfservice(this.neoProject, 'dticket');
        // if (this.hasCustomerActivatedDTicket) {
        //   this.dTicketComponent = [];
        // }


        if (!this.inCheckout) {
          return this.projectCompare();
        }
      }).then(() => {
        this.compute(this.$scope.employee.projectDifference);
        this.$scope.$watch('employee.data', () => {
          if (this.$scope.employee.data) {
            this.calcModifiedValues().then(() => {
              this.$scope.employee.plausibilites = this.PlausibilityService.check(this.$scope.employee);
            });
          }

          if (
            this.$scope.employee.data &&
            this.$scope.employee.data.parameters &&
            ('NONE' === this.$scope.employee.data.parameters.einsparungs_modell)
          ) {
            this.compute(this.$scope.employee.projectDifference);
          }
        }, true);
      }).finally(() => {
        this.$timeout(() => {
          this.$scope.loadingBausteine = false;
        });
      });
  }

  compute(invalidate_cache = false) {
    // I belive we need this but this will require more test
    invalidate_cache = false;
    this.$timeout(() => {
      return this.vbmData.getResult(invalidate_cache ? new Date() : undefined).then(
        () =>
          this.$scope.employee.plausibilites = this.PlausibilityService.check(this.$scope.employee)
      );
    }, 300);
  }

  calcModifiedValues() {
    return this.neoModifierService.calculateModifiedValues(
      this.$scope.employee.data.neo_components,
      this.$scope.employee.data.parameters.jahr,
      this.vbmData.employeeId
    );
  }

  getEmployee() {
    return this.employeeService
      .get(this.vbmData.employeeId)
      .then(employee => (this.vbmData.employees[this.vbmData.employeeId].data.person = employee));
  }

  getBausteine() {
    return this.componentsService
      .getComponents()
      .then(() => this.employeeService.getCustomerId(this.vbmData.employeeId))
      .then(customerId => this.componentsService.applyConfiguration(customerId, true))
      .then(components => {
        this.$scope.components = components;
      });
  }

  async getData() {
    await this.vbmData.getData(this.vbmData.employeeId, new Date(), false, this.$stateParams.calculation_id)

    this.$scope.basicInfoQuiz.model = this.vbmData.employees[this.vbmData.employeeId].data.parameters;

    // we tolerate an error in this call
    try {
      await this.vbmData.getResult(this.vbmData.employeeId);
    } catch (e) {

    }
    await this.getEmployee();
    await this.getBausteine();

    this.$scope.employee = this.vbmData.employees[this.vbmData.employeeId];
  }

  applyNewConfig() {
    return this.vbmService.applyConfig(this.vbmData.employees[this.vbmData.employeeId].data).then(result => {
      this.vbmData.setAlternateData(this.vbmData.employeeId, result.params, this.neoProject);
      // this method does not exist anymore
      // vbmService.save(vbmData.employees[vbmData.employeeId]);
    });
  }

  async projectCompare() {
    this.vbmData.compareSettings(this.vbmData.employees[this.vbmData.employeeId].data, this.neoProject);

    if (this.$scope.employee.projectDifference) {
      if (this.$scope.iAmGAdvisor || this.$scope.iAmAdvisor || this.$scope.iAmSpecialAdvisor) {
        return this.dialogService
          .frageDialog('Cockpit-Einstellungen wurden geändert, möchten Sie diese auf die Berechnung anwenden?', 'Projekt Einstellungen')
          .then(
            () => {
              this.applyNewConfig();
            }
          );
      } else {
        return this.applyNewConfig();
      }
    }
  }

  trigger() {
    if (this.hasOwnProperty('vbmData')) {
      this.vbmData.getResult(this.vbmData.employeeId, new Date(), true);
    } else if (this.hasOwnProperty('getResult')) {
      this.getResult(this.employeeId, new Date(), true);
    }
  }

  update() {
    this.vbmData
      .save(this.vbmData.employeeId)
      .then(() => {
      }, error => this.NotificationService.default.checkoutSession(error));
  }
}

VerguetungController.$inject = $inject;

angular
  .module('VSPApp')
  .controller('VerguetungController', VerguetungController);
