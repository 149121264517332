import {
  DELETED_BASKET_STATE,
  IN_CHECKOUT_BASKET_STATE,
  ORDER_STATUS_BUTTON_CONFIG_BY_ID,
  STORNO_BASKET_STATE
} from '../../../../services/mppService';
import {DEFAULT_MPP_ARTICLE} from '../../../../services/mppService';
import { APPROVAL_PROCESS_TYPES_BY_NAME , APPROVAL_PROCESS_STATES_BY_ID, APPROVAL_PROCESS_FILTER_CONFIGURATION } from '../../../../services/administrationService';

import bonus_control_process_dialog_html from '../../bonus_control_process/bonus_control_process_dialog.pug';
import add_old_baskets_order_id_html from '../../mpp/editable/add_old_baskets_order_id/add_old_baskets_order_id.pug';
import add_new_basket_dialog_html from '../../mpp/editable/add_new_basket/add_new_basket_dialog.pug';
import migrate_basket_html from '../../mpp/editable/migrate/migrate_basket.pug';
import add_contract_id_dialog_html from '../../mpp/editable/order_process/add_contract_id_dialog.pug';
import cancel_order_dialog_html from '../../mpp/editable/order_process/cancel_order_dialog.pug';
import ready_for_delivery_dialog_html from '../../mpp/editable/order_process/ready_for_delivery_dialog.pug';
import upload_order_process_documents_dialog_html from '../../mpp/editable/order_process/upload_order_process_documents_dialog.pug';

const $inject = [
  '$scope',
  'MppConstants',
  'mppService',
  'employeeService',
  'dialogService',
  '$state',
  '$timeout',
  'NotificationService',
  'fileService',
  'Made',
  '$q',
  'administrationService',
  'lodash',
  'authenticationService',
  'VSP_CONSTANTS',
  '$injector',
  'NeoComponentsService'
];
export default class AdministraitonMppEditController {

  constructor(
    $scope,
    MppConstants,
    mppService,
    employeeService,
    dialogService,
    $state,
    $timeout,
    NotificationService,
    fileService,
    Made,
    $q,
    administrationService,
    lodash,
    authenticationService,
    VSP_CONSTANTS,
    $injector,
    NeoComponentsService
  ) {

    Object.assign(this, {
      $scope,
      MppConstants,
      mppService,
      fileService,
      employeeService,
      dialogService,
      $state,
      $timeout,
      NotificationService,
      Made,
      $q,
      administrationService,
      lodash,
      authenticationService,
      VSP_CONSTANTS,
      $injector,
      NeoComponentsService
    });

    this.busy = false;
    this.$scope.numberOfBaskets = 0;
    this.employeeId = 0;
    this.$scope.uploader = this.Made.user.valuenet_id;
    this.iAmSpecialAdvisor = this.authenticationService.iAm('special_advisor');
    this.ORDER_STATUS_BUTTON_CONFIG_BY_ID = ORDER_STATUS_BUTTON_CONFIG_BY_ID;
    this.STORNO_BASKET_STATE = STORNO_BASKET_STATE;
    this.APPROVAL_PROCESS_TYPES_BY_NAME = APPROVAL_PROCESS_TYPES_BY_NAME;
    this.$scope.APPROVAL_PROCESS_STATES_BY_ID = APPROVAL_PROCESS_STATES_BY_ID;


    this.newBasket = {
      'leasing': 0,
      'visiblebeginDate': 0,
      'beginDate': 0,
      'visibleendDate': 0,
      'endDate': 0,
    };

    $scope.status = MppConstants.status;
    $scope.inEdit = true;
    $scope.models = {
      edit_basket: {
        edit_basket_comment: undefined
      }
    };

    $scope.formatDate = (date, tstamp = false) => {
      let dbDate = new Date(tstamp ? date * 1000 : date);

      return dbDate.getDate() + '.' + (dbDate.getMonth() + 1) + '.' + dbDate.getFullYear();
    };

    this.filters = {
      'employee_id': undefined,
      'customer_id': undefined,
      'order_id' : undefined,
      'begin_date': undefined,
      'end_date': undefined,
      'contract_id': undefined,
      'approval_state': undefined,
      'order_process_states': undefined,
      'with_deleted': undefined,
      'invoice_reference': undefined
    };

    this.loading = {
      main: false,
      search: false,
    };
    this.employeeExists = false;

    this.init();
  }

  canOpenControlDialog(basket) {
    return basket.component_state_id && basket.component_state_id === this.VSP_CONSTANTS.NEO_COMPONENT_STATE.AWAITING_BO_CONTROL.id;
  }

  openControlDialog(basket, $event){
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: bonus_control_process_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'BonusControlProcessController',
      data: {
        neo_basket: basket
      },
      controllerAs: '$ctrl',
      width: 800,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });

  }

  triggerSearch(e) {

    let searchFilters = this.getFilters();

    if (this.areFiltersEmpty(searchFilters)) {
      this.$scope.employees = [];
      return;
    }

    if (e && e.keyCode === 13) {
      e.preventDefault();
      this.search();
    }
  }

  async init(){
    this.$timeout(()=>{
      this.loading.main = true;
    });

    this.MPP_ORDER_STATUSES_BY_NAME = await this.mppService.getOrderStatusesByName();
    this.MPP_ORDER_STATUSES_BY_ID = await this.mppService.getOrderStatusesById();
    this.MPP_ORDER_STATUSES = await this.mppService.getOrderStatuses();

    let filterableOrderStatuses = [...this.MPP_ORDER_STATUSES];
    filterableOrderStatuses.shift();
    filterableOrderStatuses.push({
      'id': -1,
      'name': 'without_order_process',
      'display': 'Ohne Auftragsstatus'
    });

    this.filters = {
      ...this.filters,
      ...APPROVAL_PROCESS_FILTER_CONFIGURATION,
      order_process_states: {
        selected: [],
        options: filterableOrderStatuses
      },
      bo_flags_filters: {
        selected: [],
        options: [{
          'id': 0,
          'display': 'validiert',
          'name': 'bo_marked_as_valid',
          'value': {
            'basket.bo_marked_as_valid': true
          }
        }, {
          'id': 1,
          'display': 'nicht validiert',
          'name': 'not_bo_marked_as_valid',
          'value': {
            'basket.bo_marked_as_valid': {'$exists': false}
          }
        }, {
          'id': 2,
          'display': 'versandt',
          'name': 'bill_sent_to_leasing_company',
          'value': {
            'basket.bill_sent_to_leasing_company': true
          }
        }, {
          'id': 3,
          'display': 'noch nicht versandt',
          'name': 'not_bill_sent_to_leasing_company',
          'value': {
            'basket.bill_sent_to_leasing_company': {'$exists': false}
          }
        }]
      },
      component_states: {
        selected: [],
        options: this.VSP_CONSTANTS.NEO_COMPONENT_STATES_FILTER_OPTIONS
      },
      basket_types: {
        selected: [],
        options: this.VSP_CONSTANTS.BASKET_TYPES_FILTER_OPTIONS
      },
      billing_states: {
        selected: [],
        options: this.VSP_CONSTANTS.BILLING_STATES_FE
      }
    };

    this.filters.approval_state.options.push({
      'id': -1,
      'name': 'without_approval_process',
      'display': 'Without Approval Process',
    });

    const search = this.$state.params;
    if (search && search.employeeId ) {
      this.employee_id = this.$state.params.employeeId;
      this.filters.employee_id = +search.employeeId;
      this.search();
    }

    if (search && search.orderId) {
      this.order_id = this.$state.params.orderId;
      this.filters.order_id = this.$state.params.orderId;
      this.search();
    }

    this.$timeout(()=>{
      this.loading.main = false;
    });
  }

  openOrderIdForOldBasketsDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: add_old_baskets_order_id_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'AddOldBasketsOrderIdController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  canAddAOCyberport(basket) {
    let isManualBasket = basket.type && basket.type === 'old_mpp' && basket.bo_marked_as_valid;

    if (basket.order_id || isManualBasket) {
      return false;
    }

    return !basket.order_process;
  }

  canOpenContractDialog(basket) {
    if (!this.MPP_ORDER_STATUSES_BY_NAME) {
      return false;
    }

    return basket.order_process && [
      this.MPP_ORDER_STATUSES_BY_NAME['bill_send_leasing_company']['id'],
      this.MPP_ORDER_STATUSES_BY_NAME['order_closed']['id']
    ].indexOf(basket.order_process.state_id) !== -1 && !basket.contract_id;
  }

  openAddBasketDialog($event) {
    $event.preventDefault();
    $event.stopPropagation();


    this.dialogService.ngDialog.openConfirm({
      template: add_new_basket_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'AddNewBasketController',
      controllerAs: '$ctrl',
      data: {
        valuenetID: this.filters.employee_id
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  openMigrationDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: migrate_basket_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'MigrateBasketController',
      controllerAs: '$ctrl',
      data: {
        basket: basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });

  }

  canOpenMigrationDialog(basket, employee) {
    if (employee.to_allow_basket_migration) {
      let is_bo_validated = basket.bo_marked_as_valid;
      let order_process_exists = !!basket.order_process;
      let is_neo_active = basket.is_neo_active;

      return ![IN_CHECKOUT_BASKET_STATE, STORNO_BASKET_STATE, DELETED_BASKET_STATE].includes(basket.state) &&
        is_neo_active &&
        (order_process_exists|| is_bo_validated ) &&
        [
          this.VSP_CONSTANTS.BASKET_TYPE.NEO['id'],
          this.VSP_CONSTANTS.BASKET_TYPE.NEO_OLD['id']
        ].includes(this.VSP_CONSTANTS.BASKET_TYPE_BY_NAME[basket.type || '']['id']);
    }

    return false;
  }

  async checkIfEmployeeExists() {
    this.$timeout(() => {
      this.loading.main = true;
    });
    let result = await this.employeeService.exists({employee_id: this.filters.employee_id ? parseInt(this.filters.employee_id) : -1});
    this.$timeout(() => {
      this.employeeExists = result;
      this.loading.main = false;
    });

  }

  openContractDialog($event, basket) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: add_contract_id_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'AddContractIDController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  canOpenCancelOrderDialog(basket) {
    return basket.order_id && basket.state !== STORNO_BASKET_STATE;
  }

  openCancelOrderDialog($event, basket) {
    $event.preventDefault();

    this.dialogService.ngDialog.openConfirm({
      template: cancel_order_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'CancelOrderController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  getFilters() {
    return {
      'employee_id': this.filters.employee_id ? parseInt(this.filters.employee_id): 0,
      'customer_id': this.filters.customer_id ? parseInt(this.filters.customer_id): 0,
      'order_id': this.filters.order_id ? this.filters.order_id : undefined,
      'begin_date': this.filters.begin_date ? this.filters.begin_date: undefined,
      'end_date': this.filters.end_date ? this.filters.end_date : undefined,
      'contract_id': this.filters.contract_id ? this.filters.contract_id: undefined,
      'approval_states': this.filters.approval_state.selected,
      'order_process_states': this.filters.order_process_states.selected,
      'with_deleted': this.filters.with_deleted,
      'with_storno': this.filters.with_deleted,
      'bo_flags_filters': this.filters.bo_flags_filters.selected,
      'component_states': this.filters.component_states.selected,
      'basket_types': this.filters.basket_types.selected,
      'billing_states': this.filters.billing_states.selected,
      'invoice_reference': this.filters.invoice_reference
    };
  }

  async search() {

    let searchFilters = this.getFilters();

    if (this.areFiltersEmpty(searchFilters)) {
      this.$timeout(()=>{
        this.$scope.employees = [];
      });
      return;
    }

    this.$timeout(()=>{
      this.loading.search = true;
    });

    try {
      let employees = await this.mppService.getMPPAdministrationBaskets(searchFilters);

      this.$scope.employees = employees;

      if (searchFilters.employee_id && searchFilters.employee_id !== 0) {
        await this.checkIfEmployeeExists();
      }
      this.countBaskets(this.$scope.employees);
      this.newBasket = {
        'leasing': 0,
        'beginDate': 0,
        'endDate': 0,
      };

    } catch (e) {
      console.log('error', e);
    } finally {
      this.$timeout(()=>{
        this.loading.search = false;
      });
    }
  }

  countBaskets(employees) {
    this.$scope.numberOfBaskets = 0;
    employees.forEach(employee => {
      this.$scope.numberOfBaskets += employee.baskets.length;
    });
  }

  areFiltersEmpty(searchFilters) {
    let codependent_filters = [
      'with_deleted', 
      'with_storno', 
      'approval_states', 
    ];

    if (searchFilters['contract_id']) {
      searchFilters['contract_id'] = searchFilters['contract_id'].toLowerCase();
    }

    for (let filter in searchFilters) {
      if (searchFilters[filter] 
        && searchFilters[filter] != undefined 
        && searchFilters[filter] != ''
        && searchFilters[filter] != 'x'
        && !codependent_filters.includes(filter)) {
        return false;
      }
    }
    
    return true;
  }


  async openOrderProcessDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    let order_process_state_config = await this.mppService.getOrderStatusDialogConfig(basket.order_process.state_id);

    this.dialogService.ngDialog.openConfirm({
      template: require(`../../mpp/editable/order_process/${order_process_state_config.template_name.replace('.html', '.pug')}`)(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: `${order_process_state_config.controller_name}`,
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    }).catch((err) => {
      console.log('error dialog', err);
      this.search();
    });
  }

  openTrackingDialog(basket) {

    this.dialogService.ngDialog.openConfirm({
      template: ready_for_delivery_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'ready_for_delivery_controller',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      this.search();
    });
  }

  isOrderProcessNeedsChanges(basket) {
    return basket.order_process && basket.order_process.state_id === this.MPP_ORDER_STATUSES_BY_NAME['order_created_in_retailer_with_changes']['id'];
  }

  openOrderProcessDocumentUploadDialog(basket, $event){
    this.dialogService.ngDialog.openConfirm({
      template: upload_order_process_documents_dialog_html(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'UploadOrderProcessDocumentsDialogController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).then(() => {
      return this.search();
    });
  }


  getOrderStatusById(state_id) {
    let display = state_id && this.MPP_ORDER_STATUSES_BY_ID[state_id] && this.MPP_ORDER_STATUSES_BY_ID[state_id].display;
    if (display === null) {
      display = '-'
    }

    return display
  }

  async confirmBasketChanges(basket) {
    this.$timeout(() => {
      this.loading = {
        saving: true
      };
    });

    await this.mppService.confirmBasketChanges(basket['_id']);
    this.NotificationService.message('Die Änderungen wurden gespeichert');
    this.$timeout(() => {
      this.loading = {
        saving: false
      };
    });
    await this.search();
  }

  async updateBasket(basket, to_refresh=true) {
    // store basket
    return this.mppService.updateMPPArticleData({ basket: basket, comment: this.$scope.models.edit_basket.edit_basket_comment })
      .then(() => {
        this.NotificationService.message('Die Änderungen wurden gespeichert');
        if (to_refresh) {
          this.search();
        }
        return;
      })
      .catch((err) => {
        this.NotificationService.error(err.error.message.split('\n')[0]);
      });
  }

  deleteCart(basket) {
    this.dialogService
      .frageDialog('Durch das Zurücksetzen wird der gesamte Warenkorbinhalt entfernt. Fortfahren?', 'Warenkorb zurücksetzen')
      .then(() => {
        this.mppService.cockpitDelete(basket.employeeId, basket._id)
          .then(result => {

            if(!result) {
              this.NotificationService.error('Fehlgeschlagen.');
            }
            this.search();
          });
      });
  }

  addArticle (basket) {
    basket.articles.push(angular.copy(DEFAULT_MPP_ARTICLE));
  }

  //angular js requires this event object to be the same "key" as the one you use when dispatching it
  async handleBasketChange(changeEvent) {
    await this.search();
  }

  hasOpenInvoice(basket) {
    let state_id = this.lodash.get(basket, 'billing_process.state_id');

    return state_id && state_id !== this.VSP_CONSTANTS.BILLING_STATES_FE_BY_ID['paid']['id']
  }

  openInvoicePaymentDialog(basket, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialogService.ngDialog.openConfirm({
      template: require('../../../../modules/administration/mpp/editable/invoice_payment_dialog/invoice_payment_dialog.pug')(),
      plain: true,
      className: 'ngdialog-theme-default control_dialog cocpkit-panel',
      controller: 'InvoicePaymentDialogController',
      controllerAs: '$ctrl',
      data: {
        'basket': basket
      },
      width: 1000,
      closeByDocument: true,
      closeByEscape: true,
      closeByNavigation: true
    }).finally(() => {
      this.search();
    });
  }
}
AdministraitonMppEditController.$inject = $inject;
