import ComponentConstants from "../../../constants/ComponentConstants";

const $inject = [
  '$scope',
  '$state',
  '$timeout',
  'BonusService'
]

import car_leasing_ticket_dialog_html from '../../../dialogs/car-leasing/ticket/index.pug'
import car_leasing_cancellation_dialog_html from '../../../dialogs/car-leasing/cancellation/index.pug'
import car_leasing_first_check_dialog_html from '../../../dialogs/car-leasing/first-check/index.pug'
import car_leasing_control_process_dialog_html from '../../../dialogs/car-leasing/contract-process/index.pug'
import {
  CarLeasingFinDataDialogComponent
} from "../../../../app/car-leasing/dialogs/car-leasing-fin-data-dialog/car-leasing-fin-data-dialog.component";
import {
  CAR_LEASING_APPROVAL_STATES,
  CAR_LEASING_CONTRACT_PROCESS_STATES,
  CAR_LEASING_FIRST_CHECK_STATES,
  CAR_LEASING_IS_CANCELLED
} from '../../../../app/car-leasing/services/car-leasing-api.service';
import {
  cashbackStatusLabels
} from "../../../../app/cashback/administration/review-request/administration-cashback-review/administration-cashback-review.model";


export default class AdministrationCashbackController {
  constructor(
    $scope,
    $state,
    $timeout,
    BonusService
  ) {
    Object.assign(this, {
      $scope,
      $state,
      $timeout,
      BonusService
    });

    this.loading = {
      main: false,
      search: false
    };

    this.ComponentConstants = ComponentConstants;

    const CASHBACK_FILTER_STATES = Object.keys(cashbackStatusLabels).map((key) => {
      return {id: key, display: cashbackStatusLabels[key]}
    });

    const CASHBACK_COMPONENTS_OPTIONS = [
      {id: 'jobticket', display: 'Jobticket'},
      {id: 'bgmbudget', display: 'BGM'},
      {id: 'kinder', display: 'Kinder'}
    ];

    this.cashbacks_by_customer = [];

    this.filters = {
      'employeeId': this.$state.params.employeeId ? parseInt(this.$state.params.employeeId) : null,
      'customerId': this.$state.params.customerId ? parseInt(this.$state.params.customerId) : null,
      'bonusId': this.$state.params.bonusId,
      'components': {
        selected: [],
        options: CASHBACK_COMPONENTS_OPTIONS
      },
      'states': {
        selected: [],
        options: CASHBACK_FILTER_STATES
      },
      'createdAt': null
    };
  }

  getFilters() {
    const bonusId = this.filters['bonusId'];
    const employeeId = this.filters['employeeId'];
    const customerId = this.filters['customerId'];
    const states = this.filters['states']['selected'];
    const components = this.filters['components']['selected'];
    const createdAt = this.filters['createdAt'];

    console.log("WTF - ", createdAt);

    return {
      bonusId: !!bonusId ? bonusId : null,
      employeeId: !!employeeId ? employeeId : null,
      customerId: !!customerId ? customerId : null,
      states: states.length > 0 ? states : null,
      components: components.length > 0 ? components : null,
      createdAt: createdAt || null
    }
  }

  async search() {
    this.$timeout(() => {
      this.loading.search = true;
    });

    const filters = this.getFilters();

    try {
      this.cashbacks_by_customer = await this.BonusService.searchCashbacks(
        filters
      );
    } catch (e) {
      console.log("ERROR", e);
    }

    this.$timeout(() => {
      this.loading.search = false;
    });
  }

  async triggerSearch(e) {
    if (e && e.keyCode === 13) {
      e.preventDefault();
      await this.search();
    }
  }
}

AdministrationCashbackController.$inject = $inject
