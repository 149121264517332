// @Angular
import "@angular/compiler";
import * as angular from "angular";
import { DOCUMENT } from "@angular/common";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { downgradeComponent, downgradeInjectable, UpgradeModule } from "@angular/upgrade/static";
import { AfterViewInit, APP_INITIALIZER, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';

// Components
import { AppComponent } from './app.component';
import { LoaderDirective } from "./shared/directives/loader/loader.component";
import { MppStatusLogDialogComponent } from './administration/dialogs/mpp/status-log/mpp-status-log-dialog.component';
import { BenefitDetailsComponent } from './direct-bonus/components/benefits/benefit-details/benefit-details.component';
import { BenefitsAccountComponent } from './direct-bonus/components/benefits/benefits-account/benefits-account.component';
import { CarLeasingDetailsComponent } from './car-leasing/components/car-leasing-details/car-leasing-details.component';
import { CarLeasingCatalogComponent } from './car-leasing/components/car-leasing-catalog/car-leasing-catalog.component';
import { EmailAndPhoneValidatorComponent } from './form-elements/components/email-and-phone-validator/email-and-phone-validator.component';
import { CustomerAdministrationNewComponent } from './direct-bonus/components/customer/customer-administration-new/customer-administration-new.component';
import { BackofficeAdministrationComponent } from './direct-bonus/components/backoffice/backoffice-administration/backoffice-administration.component';
import { AdministrationConfigurationComponent } from './administration/components/incent/administration-configuration/administration-configuration.component';
import {
  AdministrationHrmonyFileImportComponent
} from './administration/components/hrmony/file-import/administration-hrmony-file-import/administration-hrmony-file-import.component';
import {
  AdministrationMppImportLeasingdataComponent
} from './administration/components/mpp/import-leasingdata/administration-mpp-import-leasingdata/administration-mpp-import-leasingdata.component';
import { ResetPreendPcDialogComponent } from './administration/dialogs/reset-preend-pc-dialog/reset-preend-pc-dialog.component';
import { ResetPcOrderConfirmDialogComponent } from './administration/dialogs/reset-pc-order-confirm-dialog/reset-pc-order-confirm-dialog.component';
import { TogglePcLeaseBlockingDialogComponent } from './administration/dialogs/neo/toggle-pc-lease-blocking-dialog/toggle-pc-lease-blocking-dialog.component';
import { HoneypotFormComponent } from './shared/components/form-mixins/honeypot/honeypot-form.component';
import { ConsentDialogComponent } from './shared/components/consent-dialog/consent-dialog.component';
import { CashbackReceiptUploadDialogComponent } from './cashback/dialogs/cashback-receipt-upload-dialog/cashback-receipt-upload-dialog.component';
import { AdministrationCashbackReviewComponent } from './cashback/administration/review-request/administration-cashback-review/administration-cashback-review.component';

// Modules
import { SharedModule } from './shared/shared.module';
import { ExamplesModule } from "./examples/examples.module";
import { CarLeasingModule } from "./car-leasing/car-leasing.module";
import { BackOfficeModule } from './back-office/back-office.module';
import { DirectBonusModule } from './direct-bonus/direct-bonus.module';
import { FoodVouchersModule } from './food-vouchers/food-vouchers.module';
import { FormElementsModule } from './form-elements/form-element.module';
import { NeoCalculationBookmarkModule } from './neo-calculation-bookmark/neo-calculation-bookmark.module';

// Services
import { BootstrapService } from "./bootstrap.service";
import { AlertService } from "./shared/services/alert.service";
import { PopupService } from "./shared/services/popup.service";
import { AdministrationService } from './administration/services/administration.service';
import { NeoCalculationBookmarkService } from "./neo-calculation-bookmark/services/neo-calculation-bookmark.service";
import { DirectBonusService } from "./direct-bonus/services/direct-bonus.service";

import {DticketOrderModule} from "./dticket-order/dticket-order.module";
import {DticketOrderService} from "./dticket-order/services/dticket-order.service";
import { PrufenButtonComponent } from "./form-elements/components/prufen-button/prufen-button.component";

// REVIEW try to call this method from elsewhere, since it isn't working with ng1 in the mix
registerLocaleData(localeDe, localeDeExtra);

declare var require: any;

const bootstrapAppFactory = (bootstrapService: BootstrapService) => {
  return (): Promise<any> => {
    return bootstrapService.deferredBootstrap();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AdministrationMppImportLeasingdataComponent,
    MppStatusLogDialogComponent,
    AdministrationHrmonyFileImportComponent,
    AdministrationConfigurationComponent,
    ResetPcOrderConfirmDialogComponent,
    TogglePcLeaseBlockingDialogComponent,
    ResetPreendPcDialogComponent,
    CashbackReceiptUploadDialogComponent,
    AdministrationCashbackReviewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UpgradeModule,
    UIRouterUpgradeModule.forRoot(),
    ExamplesModule,
    CarLeasingModule,
    DirectBonusModule,
    DticketOrderModule,
    FoodVouchersModule,
    SharedModule,
    BackOfficeModule,
    NeoCalculationBookmarkModule,
    FormElementsModule
  ],
  providers: [
    {
      provide: 'Made',
      useFactory: ($injector: any) => $injector.get('Made'),
      deps: ['$injector']
    },
    {
      provide: '$state',
      useFactory: ($injector: any) => $injector.get('$state'),
      deps: ['$injector']
    },
    {
      provide: '$location',
      useFactory: ($injector: any) => $injector.get('$location'),
      deps: ['$injector']
    },
    {
      provide: 'EmployeeService',
      useFactory: ($injector: any) => $injector.get('employeeService'),
      deps: ['$injector']
    },
    {
      provide: 'UserService',
      useFactory: ($injector: any) => $injector.get('userService'),
      deps: ['$injector']
    },
    {
      provide: 'authenticationService',
      useFactory: ($injector: any) => $injector.get('authenticationService'),
      deps: ['$injector']
    },
    {
      provide: 'customerService',
      useFactory: ($injector: any) => $injector.get('customerService'),
      deps: ['$injector']
    },
    {
      provide: 'VbmDataService',
      useFactory: ($injector: any) => $injector.get('vbmData'),
      deps: ['$injector']
    },
    {
      provide: 'CommonService',
      useFactory: ($injector: any) => $injector.get('CommonService'),
      deps: ['$injector']
    },
    {
      provide: 'fileService',
      useFactory: ($injector: any) => $injector.get('fileService'),
      deps: ['$injector']
    },
    {
      provide: 'filmService',
      useFactory: ($injector: any) => $injector.get('filmService'),
      deps: ['$injector']
    },
    {
      provide: 'moment',
      useFactory: ($injector: any) => $injector.get('moment'),
      deps: ['$injector']
    },
    {
      provide: 'authenticationService',
      useFactory: ($injector: any) => $injector.get('authenticationService'),
      deps: ['$injector']
    },
    {
      provide: 'userService',
      useFactory: ($injector: any) => $injector.get('userService'),
      deps: ['$injector']
    },
    {
      provide: 'VSP_CONSTANTS',
      useFactory: ($injector: any) => $injector.get('VSP_CONSTANTS'),
      deps: ['$injector']
    },
    {
      provide: 'UsercentricsConstants',
      useFactory: ($injector: any) => $injector.get('UsercentricsConstants'),
      deps: ['$injector']
    },
    {
      provide: 'ByteplantService',
      useFactory: ($injector: any) => $injector.get('ByteplantService'),
      deps: ['$injector']
    },
    {
      provide: 'mppService',
      useFactory: ($injector: any) => $injector.get('mppService'),
      deps: ['$injector']
    },
    {
      provide: 'BonusService',
      useFactory: ($injector: any) => $injector.get('BonusService'),
      deps: ['$injector']
    },
    BootstrapService,
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapAppFactory,
      deps: [BootstrapService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "de"
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
  ]
})
export class AppModule implements AfterViewInit {
  constructor(
    private upgrade: UpgradeModule,
    private bootstrapService: BootstrapService,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngAfterViewInit() {}

  ngDoBootstrap() {
    const bootstrapService = this.bootstrapService;

    let bikeleasing_contract_regex = bootstrapService.IS_DEV ? undefined : new RegExp(/^([abcdef0-9]*)-([abcdef0-9]*)-([abcdef0-9]*)-([abcdef0-9]*)-([abcdef0-9]*)$/);

    angular.module('VSPApp')
      .constant('USERLANE_DISABLED', bootstrapService.USERLANE_DISABLED)
      .constant('IS_DEV_ENVIRONMENT', bootstrapService.IS_DEV)
      .constant('IS_STAGE_ENVIRONMENT', bootstrapService.IS_STAGE)
      .constant('VIDEOS_DISABLED', bootstrapService.VIDEOS_DISABLED)
      .constant('VSP_CONSTANTS', bootstrapService.VSP_CONSTANTS)
      .constant('BIKELEASING_CONTRACT_REGEX', bikeleasing_contract_regex)
      .constant('BUILD_VERSION', bootstrapService.BUILD_VERSION)

      // downgraded components
      .directive('loader', downgradeComponent({ component: LoaderDirective }) as angular.IDirectiveFactory)
      .directive('carLeasingDetails', downgradeComponent({ component: CarLeasingDetailsComponent }) as angular.IDirectiveFactory)
      .directive('carLeasingCatalog', downgradeComponent({ component: CarLeasingCatalogComponent }) as angular.IDirectiveFactory)
      .directive('directBonusCustomerAdministration', downgradeComponent({ component: CustomerAdministrationNewComponent }) as angular.IDirectiveFactory)
      .directive('directBonusBackofficeAdministration', downgradeComponent({ component: BackofficeAdministrationComponent }) as angular.IDirectiveFactory)
      .directive('directBonusBenefitsAccount', downgradeComponent({ component: BenefitsAccountComponent }) as angular.IDirectiveFactory)
      .directive('directBonusBenefitDetails', downgradeComponent({ component: BenefitDetailsComponent }) as angular.IDirectiveFactory)
      .directive('emailAndPhoneValidator', downgradeComponent({ component: EmailAndPhoneValidatorComponent }) as angular.IDirectiveFactory)
      .directive('prufenButton', downgradeComponent({ component: PrufenButtonComponent }) as angular.IDirectiveFactory)
      .directive('honeypotForm', downgradeComponent({ component: HoneypotFormComponent }) as angular.IDirectiveFactory)
      .directive('vnConsentDialog', downgradeComponent({ component: ConsentDialogComponent }) as angular.IDirectiveFactory)
      .directive('administrationCashbackReview', downgradeComponent({ component: AdministrationCashbackReviewComponent }) as angular.IDirectiveFactory)

      // downgrade services
      .factory('NotificationService', downgradeInjectable(AlertService))
      .factory('PopupService', downgradeInjectable(PopupService))
      .factory('MatDialogService', downgradeInjectable(MatDialog))
      .factory('AdministrationService', downgradeInjectable(AdministrationService))
      .factory('NeoCalculationBookmarkService', downgradeInjectable(NeoCalculationBookmarkService))
      .factory('DirectBonusService', downgradeInjectable(DirectBonusService))
      .factory('DticketOrderService', downgradeInjectable(DticketOrderService));


    // to tell AngularJS about Attribute directive
    angular.module('VSPApp')
      .run(['$templateCache', ($templateCache: any) => {
        const schemaform_names = [
          'actions-trcl',
          'actions',
          'adressAB',
          'array',
          'baustein',
          'bausteinselect',
          'bausteinselectdisabled',
          'box',
          'checkbox',
          'checkboxes',
          'circletext',
          'components',
          'customclasscontainer',
          'customclassdiv',
          'default',
          'documentdownloads',
          'field4',
          'fieldset-trcl',
          'fieldset',
          'fileinput',
          'help',
          'iframe-popup',
          'instantupdate',
          'list',
          'locationselect',
          'percent',
          'progressbar',
          'radio-buttons',
          'radios-inline',
          'radios',
          'section',
          'select',
          'servicebox',
          'span',
          'submit',
          'switchbox',
          'tabarray',
          'table',
          'tabs',
          'textarea',
          'wizardbox',
        ]

        for (const schemaform_name of schemaform_names) {
          const template = require(`../app-ajs/directives/schemaforms/${schemaform_name}.html`)
          $templateCache.put(`app-ajs/directives/schemaforms/${schemaform_name}.html`, template.default);
        }

      }])
      .config(['$provide', ($provide: any) => {
        $provide.decorator('loaderDirective', ['$delegate', ($delegate: any) => {
          $delegate[0].restrict = 'A';
          return $delegate;
        }]);
      }])

    this.upgrade.bootstrap(document.documentElement, ['VSPApp'], { strictDi: false });
  }
}
