const ComponentIdConstants = {
  1: 'sachbezug',
  2: 'strom',
  3: 'essenscheck',
  4: 'internet',
  5: 'kinder',
  6: 'entfernung',
  7: 'dienstwagen_miete',
  8: 'dienstwagen_auslage',
  9: 'werbeflaeche',
  10: 'handy',
  11: 'personal_rabatt',
  12: 'erholungsbeihilfe',
  13: 'VMA_PST',
  14: 'berufskleidung',
  15: 'gesundheitsvorsorge',
  19: 'Sonstiges',
  20: 'festnetz',
  21: 'EBike',
  22: 'pc',
  23: 'heimarbeit',
  24: 'VMA_STSVFREI',
  25: 'besonderer_anlass',
  26: 'BKV',
  28: 'Bikeleasing',
  29: 'bgmbudget',
  30: 'jobticket',
  50: 'car_leasing',
  99: 'versorgung'
};

export default ComponentIdConstants;
