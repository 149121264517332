
import {BasketArticle} from '../../models/basket-article';
import {BasketItem} from '../../models/basket-item';
import {BasketRepositoryService} from '../../services/backend/basket-repository.service';

import {PCComponentModel} from '../../models/pc-component-model';
import {CHECKOUT_TYPES_BY_NAME} from "../../services/bonus/checkout_types";

const NEO_COMPONENT_MPP_COOKIE_KEY = 'NEO_COMPONENT_MPP_COOKIE';

const NOTIFICATION_ARTICLE_AVAILABILITY_HAS_CHANGED = 'Die Verfügbarkeit Ihrer gewählten Artikel hat sich geändert.';
const $inject = [
  '$rootScope',
  '$scope',
  '$q',
  '$state',
  '$location',
  '$filter',
  '$timeout',
  '$window',
  'mppService',
  'MppConstants',
  'Made',
  'vbmData',
  'NotificationService',
  'customerService',
  'vbmService',
  'employeeService',
  'dialogService',
  'BonusService',
  'lodash',
  'authenticationService',
  'NeoComponentsService',
  'VSP_CONSTANTS',
  'basketRepositoryService'
];
export default class MppController {


  constructor(
    $rootScope,
    $scope,
    $q,
    $state,
    $location,
    $filter,
    $timeout,
    $window,
    mppService,
    MppConstants,
    Made,
    vbmData,
    NotificationService,
    customerService,
    vbmService,
    employeeService,
    dialogService,
    BonusService,
    lodash,
    authenticationService,
    NeoComponentsService,
    VSP_CONSTANTS,
    basketRepositoryService
  ) {

    Object.assign(this, {
      $scope,
      $q,
      $state,
      $window,
      vbmService,
      customerService,
      BonusService,
      lodash,
      authenticationService,
      Made,
      NeoComponentsService,
      VSP_CONSTANTS
    });

    /** @member {BasketRepositoryService} */
    this.basketRepositoryService = basketRepositoryService;

    this.loading = true;
    this.$scope = $scope;
    this.$q = $q;
    this.mppService = mppService;
    this.$timeout = $timeout;

    this.$scope.products = [];
    this.$scope.forms = {};

    this.mppService = mppService;
    this.$location = $location;
    this.vbmData = vbmData;
    this.NotificationService = NotificationService;
    this.dialogService = dialogService;
    this.MppConstants = MppConstants;
    this.bonusMppCookieKey = this.MppConstants.bonusMppCookieKey;

    this.$scope.alternateUser = angular.isUndefined($location.search().employeeId);
    this.isAdvisor = !angular.isUndefined($location.search().calc);
    this.$scope.iAmSpecialAdvisor = authenticationService.iAm('special_advisor');
    if (('string' === typeof $location.search().employeeId) && !isNaN($location.search().employeeId)) {
      vbmData.employeeId = parseInt($location.search().employeeId);
    }

    this.$scope.employeeId = vbmData.employeeId || Made.user.valuenet_id;

    this.is_bonus = !!this.$state.params.mpp_data;
    this.is_neo = this.$state.params.neo_data || this.isAdvisor;


    // to ensure MPP knows it must go to NEO
    if (this.is_neo) {
      this.removeBonusCache();
    }


    if (!this.is_bonus) {
      // check cookies
      // let mpp_cookie = this.CookieService.getCookie(this.bonusMppCookieKey);
      let mpp_cookie = this.getBonusCache();

      if (mpp_cookie) {
        this.is_bonus = true;
        this.bonus_project_id = mpp_cookie.bonus_project_id;
        this.bonus_id = mpp_cookie.bonus_id;
        this.employee_bonus_checkout_config = mpp_cookie.employee_bonus_checkout_config;
        if (mpp_cookie.custom_checkout_date) {
          this.custom_checkout_date = new Date(mpp_cookie.custom_checkout_date);
        }
        this.checkout_type = mpp_cookie.checkout_type;
        if (this.checkout_type.name === CHECKOUT_TYPES_BY_NAME['future']['name']) {
          this.future_bonus_id = this.employee_bonus_checkout_config.bonus_id;
        }
      }

    } else {
      // this.bonus_limits = this.$state.params.mpp_data.bonus_limits;
      this.custom_checkout_date = this.$state.params.mpp_data.custom_checkout_date;
      this.bonus_project_id = this.$state.params.mpp_data.bonus_project_id;
      this.bonus_id = this.$state.params.mpp_data.bonus_id;
      this.employee_bonus_checkout_config = angular.copy(this.$state.params.employee_bonus_checkout_config);
      this.checkout_type = this.$state.params.mpp_data.checkout_type;
      if (this.checkout_type.name === CHECKOUT_TYPES_BY_NAME['future']['name']) {
        this.future_bonus_id = this.employee_bonus_checkout_config.bonus_id;
      }
      // set cookie
      let cookie = {
        bonus_project_id: this.bonus_project_id,
        bonus_id: this.$state.params.mpp_data.bonus_id,
        employee_bonus_checkout_config: this.employee_bonus_checkout_config,
        checkout_type: this.checkout_type
      };

      if (this.custom_checkout_date) {
        Object.assign(cookie, {
          custom_checkout_date: this.custom_checkout_date.toUTCString()
        });
      }

      // this.CookieService.setCookie(cookie, this.bonusMppCookieKey );
      this.setBonusCache(cookie);

    }
    // initializations needed only if in Bonus
    if (this.is_bonus) {

      this.models = {
        isPaymentMethodBudget: false,
        isPaymentMethodExchange: false
      };


      this.can_checkout_bonus = false;
      this.budget_not_enough = false;
      this.bonus_start_date_after_bonus_project_end_date = false;
    }

    let set_up_promises = [];

    /** @type Basket */
    this.basket = null;
    this.basketId = '';

    this.hasArticles = false;
    this.errors = false;
    this.once = true;
    this.oldUpdate = false;
    this.hasEvent = false;
    this.isValidCart = false;
    this.is_over_bonus_brutto_limit = false;
    this.leasingConst = this.lodash.cloneDeep(MppConstants.calculation);
    this.requiredProductGroup = MppConstants.requiredGroups;
    this.mobileRequiredGroups = MppConstants.mobileRequiredGroups;
    this.mobileCart = false;

    this.cartMinimumBruttoValue = undefined;
    this.cartValueNetto = 0;
    this.validBruttoMinimum = true;

    set_up_promises.push(
      this.mppService.hasUnfinishedBuyingProcess(this.$scope.employeeId).then((hasUnfinishedBuyingProcess) => {
        this.hasUnfinishedBuyingProcess = hasUnfinishedBuyingProcess;
      })
    )
    if (this.is_bonus) {

      //get bonus component name
      set_up_promises.push(
        this.BonusService.getBonusComponentNameByBonusId(this.bonus_id).then((bonus_component_name) => {
          return this.bonus_component_name = bonus_component_name;
        })
      );
      // bonus -> as per VN-786 I take the configuration from the customer
      set_up_promises.push(
        this.customerService.getConfigurationByEmployeeId(this.$scope.employeeId).then(async configuration => {
          this.customer_configration = configuration;
          let cart_min_value = this.lodash.get(this.customer_configration, 'neo_config.pc.mindestbestellwert', MppConstants.minimum);

          this.cartMinimumBruttoValue = cart_min_value;
          this.endprice_percentage = this.lodash.get(this.customer_configration, 'neo_config.pc.endprice_percentage');

          // initate cart
          return this.makeCart();
        })
      );

      if (!this.bonus_limits) {
        set_up_promises.push(
          this.BonusService.calculatePCLeasing(
            this.$scope.employeeId,
            this.bonus_project_id,
            this.bonus_id, // the function will take into account that this is future but needs the real bonus to work
            this.customer_configration ? this.customer_configration.id : undefined,
            this.custom_checkout_date,
            this.checkout_type['name']
          ).then(async bonus_limits => {
            this.bonus_limits = bonus_limits;
            await this.extract_from_bonus_limits();
          })
        );
      }
    } else {

      this.is_neo = true;
      this.neo_data = this.$state.params.neo_data;
      this.neo_component = this.lodash.get(this.neo_data, 'neo_component');

      if (!this.neo_component) {
        this.removeNeoComponentCache();
      }

      let neo_promises = [];

      // get employee data
      neo_promises.push(
        vbmData.getResult(vbmData.employeeId, new Date()).then(() => {
          this.employee = vbmData.employees[vbmData.employeeId];
        })
      );



      neo_promises.push(
        customerService.getNeoProjectByEmployeeId(this.$scope.employeeId).then(neo_project => {
          this.neo_project = neo_project;
        })
      );

      neo_promises.push(
        customerService.getConfigurationByEmployeeId(this.$scope.employeeId).then(configuration => {
          this.customer_configration = configuration;
          let leasing_rate = this.lodash.get(this.customer_configration, 'neo_config.pc.leasing_rate', this.leasingConst.leasing);
          this.leasingConst.leasing = leasing_rate;
          this.endprice_percentage = this.lodash.get(this.customer_configration, 'neo_config.pc.endprice_percentage');
          this.cartMinimumBruttoValue = this.lodash.get(this.customer_configration, 'neo_config.pc.mindestbestellwert', MppConstants.minimum);
        })
          .then( () => {


            return this.mppService.getHandlingFee(true, this.customer_configration['_id'])
              .then(handling_fee_brutto => {
                this.handling_fee_brutto = handling_fee_brutto;
              });
          })
      );


      set_up_promises.push(
        Promise.all(neo_promises).then(async () => {
          let hasCustomerActivatedPC = await this.NeoComponentsService.hasCustomerActivatedPC({employee_id: this.$scope.employeeId});
          if (hasCustomerActivatedPC) {

            let pc_component = await this.NeoComponentsService.getAvailableComponent(PCComponentModel.ID, this.$scope.employeeId);

            if (!pc_component) {
              pc_component = await this.NeoComponentsService.createComponent(PCComponentModel.ID, this.$scope.employeeId);
            }

            if (pc_component) {
              this.neo_component = pc_component;
              return this.setUpBasketForComponent();
            } else {
              throw 'You are not allowed to do more checkouts';
            }
          } else {
            return this.makeCart();
          }
        })
      );
    }

    this.set_up_promise = Promise.all(set_up_promises).finally(() => {
      // this.validateCart();
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });
  }

  async extract_from_bonus_limits() {
    this.leasing_rate = this.bonus_limits.leasing_rate;
    this.months_left_this_year = this.bonus_limits.months_left_this_year;
    this.is_overspending_allowed = this.bonus_limits.has_bbg;
    this.is_leader = this.bonus_limits.is_leader;

    // if the user has BBG then he is by default EXCHANGE else he is BUDGET
    this.models.isPaymentMethodBudget = !this.is_overspending_allowed;
    if (this.models.isPaymentMethodBudget) {
      await this.handleBonusPaymentMethodChange('budget');
    } else {
      await this.handleBonusPaymentMethodChange('exchange');
    }
  }

  removeNeoComponentCache() {
    // this.CookieService.removeCookie(COOKIE_KEY);
    this.$window.localStorage.removeItem(NEO_COMPONENT_MPP_COOKIE_KEY);
  }

  getNeoComponentCache() {
    return angular.fromJson(this.$window.localStorage.getItem(NEO_COMPONENT_MPP_COOKIE_KEY));
  }

  setNeoComponentCache(cookie) {
    this.$window.localStorage.setItem(NEO_COMPONENT_MPP_COOKIE_KEY, angular.toJson(cookie));
  }

  removeBonusCache() {
    // this.CookieService.removeCookie(this.bonusMppCookieKey);
    this.$window.localStorage.removeItem(this.bonusMppCookieKey);
  }

  getBonusCache() {
    return angular.fromJson(this.$window.localStorage.getItem(this.bonusMppCookieKey));
  }

  setBonusCache(cookie) {
    this.$window.localStorage.setItem(this.bonusMppCookieKey, angular.toJson(cookie));
  }

  get_brutto_handling_fee() {
    if (this.neo_component) {
      return this.neo_component.basket ? this.neo_component.basket.handlingFeeBrutto : 0;
    }
    return this.handling_fee_brutto || 0;
  }

  /**
   * Those are old checks which will be ovirriden if we are using old functionality
   * */

  set hasArticles(value) {
    this._hasArticles = value;
  }

  get hasArticles() {
    if (this.neo_component && this.basket) {
      return !this.basket.isEmpty;
    }
    return this._hasArticles;
  }

  set errors(value) {
    this._errors = value;
  }

  get errors() {
    if (this.neo_component && this.basket) {
      return false;
    }
    return this._errors;
  }

  set cartBruttoSum(value) {
    this._cartBruttoSum = value;
  }

  get cartBruttoSum() {
    if (this.neo_component && this.basket) {
      return this.basket.total_brutto;
    }
    return this._cartBruttoSum;
  }

  set validBruttoMinimum(value) {
    this._validBruttoMinimum = value;
  }

  get validBruttoMinimum() {
    if (this.neo_component) {
      return this.neo_component.is_total_brutto_valid;
    }
    return this._validBruttoMinimum;
  }

  set leasing(value) {
    this._leasing = value;
  }

  get leasing() {
    if (this.neo_component && this.basket) {
      return this.basket.leasing_rate;
    }
    return this._leasing;
  }

  set isValidCart(value) {
    this._isValidCart = value;
  }

  get isValidCart() {
    if (this.neo_component && this.basket) {
      return this.basket.containsMainArticle;
    }
    return this._isValidCart;
  }

  set canShop(value) {
    this._canShop = value;
  }

  get canShop() {
    if (this.neo_component && this.basket) {
      return !this.basket.isClosed && !this.basket.isPreOrder && this.can_do_checkout;
    } else if (this.is_bonus) {
      return true;
    }

    return this._canShop;
  }

  /**
   * New method to set up the cart
   * */
  async setUpBasketForComponent() {
    /** @type Basket */
    this.basket = await this.neo_component.basket;

    if (!this.basket) {
      this.NotificationService.errors('Can not find basket');
      throw 'can not find basket';
    }

    this.can_do_checkout = await this.neo_component.canDoCheckout();

    let changedOrDeletedArticles = await this.basketRepositoryService.loadBasket(this.basket);
    if (this.errors || changedOrDeletedArticles.length > 0) {
      this.NotificationService.error(NOTIFICATION_ARTICLE_AVAILABILITY_HAS_CHANGED);
    }

    await this.saveAndUpdateBasketOnComponent(); // to refresh pc_config data
  }

  /**
   * Initalize cart for current employeeId
   *
   * Prepares to Cart
   * dbCart for DB save
   * cart for user visualization, has additional information such as gross value
   *
   */
  async makeCart() {
    // get cart for user

    let cart = await this.getCurrentCart();

    let updated = false;
    // clone cart object for further manipulation
    this.dbCart = angular.copy(cart);
    this.basketId = this.dbCart._id;
    this.cartBruttoSum = 0;

    // 7 Tage = 604800
    // 30 Tage = 2592000

    // figure out if the basket needs to be updated
    let startDate = this.dbCart.created,
      current = parseInt(new Date().getTime() / 1000);

    if (this.dbCart.updated) {
      startDate = this.dbCart.updated;
    }

    if ((current - startDate) > 259200 && !this.dbCart.locked) {
      this.dbCart.updated = current;
      this.oldUpdate = updated = true;
    }

    let currentArticles = cart.articles;

    if (currentArticles.length) {
      this.hasArticles = true;
    }

    // check validity of the articles in the current cart
    for (let i = 0; i < currentArticles.length; i++) {
      let cA = currentArticles[i];

      // get article from source
      let article = await this.mppService.getSpecificArticle(cA.Artikelnummer);

      cA.error = false;

      if (!article) {
        this.errors = true;
        cA.error = true;
      }

      // set netprice if it is undefined to new cart
      if (this.dbCart.articles[i].netprice === undefined) {
        this.dbCart.articles[i].netprice = article.Calculated_Preis;
      }

      // update current cart with new settings
      cA.netto = this.dbCart.articles[i].netprice;
      cA.price = this.dbCart.articles[i].price;
      cA.open = false; // Visual helper

      // since the cart was very old we need to get the new prices from the shop
      if (updated && article) {
        this.dbCart.articles[i].price = article.Preis_Brutto;
        this.dbCart.articles[i].netprice = article.Calculated_Preis;
      }

      // Update the article name if it was missing
      if (!this.dbCart.articles[i].Artikelname && !cA.error) {
        this.dbCart.articles[i].Artikelname = article.Artikelname;
        if (!this.oldUpdate) {
          this.oldUpdate = true;
        }
      }

      // update current article name
      cA.name = this.dbCart.articles[i].Artikelname ? this.dbCart.articles[i].Artikelname : undefined;

      // calculate sums
      this.cartValueNetto += cA.netto * cA.quantity;
      this.cartBruttoSum += cA.price * cA.quantity;
      this.validBruttoMinimum = this.is_minimum_brutto_valid();

      // error handling
      if (this.errors && this.once) {
        this.once = false;
        this.NotificationService.error('Die Verfügbarkeit Ihrer gewählten Artikel hat sich geändert, bitte tauschen Sie die markierten Artikel aus oder entfernen Sie diese.');
      }

    }

    // attach cart to controller with updated currentArticles
    this.cart = {
      ...cart,
      articles: currentArticles
    };

    await this.setCheckoutConfig();

    // indicate if user is allowed to change cart
    if (this.is_bonus) {
      this.canShop = true;
    } else {
      this.canShop = !cart.closed && !cart.preorder;
    }

    // recalculate leasing if cart is not locked and was changed
    if (this.oldUpdate && !cart.locked) {
      let sum = 0,
        lC = this.leasingConst;

      for (let i = 0; i < this.cart.articles.length; i++) {
        let cCA = cart.articles[i];
        sum += cCA.netto * cCA.quantity;
      }
      this.leasing = (this.cart.articles && this.cart.articles.length > 0) ? parseFloat(((sum + lC.shipping) * lC.leasing).toFixed(2)) * 1.19 : 0;

      if (cart.leasing !== this.leasing) {
        this.NotificationService.error('Die Preise Ihrer gewählten Artikel hat sich geändert, bitte übernehmen Sie die aktuellen Preise und die neue Leasingrate.');
      }

    } else {
      this.leasing = cart.leasing;
    }

    // check validity of cart
    this.validateCart();
  }

  async getCurrentCart() {

    if (this.neo_component && this.neo_component.basket) {
      return this.neo_component.basket;
    }

    try {
      return await this.mppService.getCart(this.$scope.employeeId, this.bonus_project_id);
    } catch (error) {
      this.$state.go('inApp.neo.side.verguetung');
    }
  }

  TypeOf(article) {
    console.log(article instanceof BasketItem);
  }

  /**
   *
   * Checks if current article is already in the cart,
   * if yes check if quantity change happened.
   *
   * After checking the new cart will be saved.
   *
   * This procedere is necessary for multi-device using without finalizing the cart.
   *
   * @param {object} article - Current article object, which should be added
   * @param {integer} quantity - default set to 1
   *
   * #TODO_LEX: This method should be called in a way that it is clear what type the article has (basket item or article)
   */
  async setArticleOnCart(article, quantity = 1) {
    // reuse old method but add new logic for the way
    if (this.basket) {
      if (article instanceof BasketItem) {
        this.basket.updateItem(article);
      } else {
        // #COMMENT_LEX: This had been done via the CyberportProduct before. An Article should be passed to this
        // method already and the conversion from cyberport fields should be done somehwere else before
        this.basket.addArticle(new BasketArticle( // #TODO_LEX: refactor to avoid having an article identifier twice
          article.Artikelnummer,
          article.Artikelname,
          article.Preis_Brutto,
          article.Calculated_Preis
        ), quantity);
      }

      await this.saveAndUpdateBasketOnComponent();

      this.$timeout(() => {
        this.leasing = this.basket.leasing_rate;
      });
      return;
    }

    let addDbArticle = {
        Artikelnummer: article.Artikelnummer,
        Artikelname: article.Artikelname,
        price: article.Preis_Brutto,
        netprice: article.Calculated_Preis,
        quantity: quantity,
      },
      addArticle = {
        Artikelnummer: article.Artikelnummer,
        price: article.Preis_Brutto,
        netprice: article.Calculated_Preis,
        quantity: quantity,
        name: article.Artikelname,
        netto: article.Calculated_Preis,
      },
      update = false,
      quantityChanged = false;

    if (!this.hasArticles) {
      this.hasArticles = true;
      update = true;

    } else {
      for (let i = 0; i < this.cart.articles.length; i++) {
        let cCA = this.cart.articles[i];

        if (cCA.Artikelnummer !== article.Artikelnummer) {
          update = true;
        } else {
          update = false;
          quantityChanged = this.dbCart.articles[i].quantity !== quantity;

          this.dbCart.articles[i].quantity = cCA.quantity = quantity;
          break;
        }
      }
    }

    if (update) {
      this.dbCart.articles.push(addDbArticle);
      this.cart.articles.push(addArticle);
    }

    if (update || quantityChanged) {

      if (this.is_bonus) {
        await this.save(article);
        this.checkForErrors();
      } else {
        if (this.neo_component) {
          this.checkForErrors();
        } else {
          // OLD way
          if (this.dbCart.savedMPP) {
            this.dbCart.savedMPP = false;
            this.dbCart.state = 'created';
          }
          this.dbCart.leasing = await this.mppService.updateLeasing(this.dbCart);
          this.save(article);
        }
      }
    }

    this.validateCart();
  }

  async saveAndUpdateBasketOnComponent() {
    this.basket = await this.basketRepositoryService.saveBasket(this.basket);
    // #COMMENT_LEX: took me a while to figure out why the advantage calculation was not updated. We don't really
    // own the basket here but it is owned by the neo component
    if (this.neo_component) {
      this.neo_component.basket = this.basket;
    }
  }

  /**
   *
   * Removes an article from the carts (dbCart, cart)
   *
   * This is only possible if the MPP checkout isn't complete and thous the cart isn't closed.
   *
   * @param {number} cartArticleIndex - cart array index for the given article
   */
  async removeArticle(cartArticleIndex) {
    if (this.basket) {
      this.basket.removeItemAt(cartArticleIndex);
      await this.saveAndUpdateBasketOnComponent();

      // #TODO_LEX: is this timeout really required?
      this.$timeout(() => {
        this.leasing = this.basket.leasing_rate;
      });
      return;
    }

    if (this.canShop) {
      this.checkForErrors(cartArticleIndex); // Check if MPP saving is possible due to article errors

      this.cart.articles.splice(cartArticleIndex, 1);
      this.dbCart.articles.splice(cartArticleIndex, 1);

      let validCart = !!this.cart.articles.length;
      this.dbCart.leasing = validCart ? await this.mppService.updateLeasing(this.dbCart) : 0;
      this.hasArticles = validCart;

      if (!this.is_bonus && !this.neo_component) {
        if (this.dbCart.savedMPP) {
          this.mppService.saveLeasingToMPP(this.$scope.employeeId, 0);
        }
      }
      await this.save();
      this.checkForErrors();
      this.validateCart();
    } else {
      this.NotificationService.error('Vorgang nicht möglich');
    }
  }

  is_minimum_brutto_valid() {
    return this.calculate_remaining_brutto_to_min() < 0;
  }

  calculate_full_brutto_amount() {
    return this.cartBruttoSum + this.get_brutto_handling_fee();
  }

  calculate_remaining_brutto_to_min() {
    return this.cartMinimumBruttoValue - this.calculate_full_brutto_amount();
  }

  calculate_overspending_brutto_amount() {
    return this.calculate_full_brutto_amount() - this.bonus_limits.max_brutto_value;
  }

  /**
   * Tries to set / update the dbCart to the database.
   * Success: Further update and check of the plausibility
   * Error: Current tryed article will be removed from the cart
   *
   * @param {object} article - saves the current cart to the database
   */
  save(article = undefined) {
    this.loading = true;
    return this.mppService.setCart(this.dbCart).then(result => {
      if (result) {
        this.leasing = this.dbCart.leasing;

        let sum = 0;
        this.cartValueNetto = 0;
        for (let i = 0; i < this.cart.articles.length; i++) {
          let cCA = this.cart.articles[i];

          sum += cCA.price * cCA.quantity;
          this.cartValueNetto += cCA.netto * cCA.quantity;
        }

        this.cartBruttoSum = sum;
        this.validBruttoMinimum = this.is_minimum_brutto_valid();
        return true;
      } else {
        // unable to save -> remove new article from cart
        if (!angular.isUndefined(article)) {
          for (let i = 0; i < this.cart.articles.length; i++) {
            let cCA = this.cart.articles[i];

            if (cCA.Artikelnummer === article.Artikelnummer) {
              this.dbCart.articles.splice(i, i + 1);
              this.cart.articles.splice(i, i + 1);
              break;
            }
          }
        }

        return false;
      }
    }).then((result) => {
      if (result) {
        return this.setCheckoutConfig();
      }
    }).finally(() => {
      this.$timeout(() => {
        this.loading = false;
      }, 0);
    });
  }

  async setCheckoutConfig() {
    if (!this.cart || !this.is_bonus || !this.isPaymentTypeSelected()) {
      return;
    }
    this.bonus_start_date_after_bonus_project_end_date = false;
    this.budget_not_enough = false;
    return this.BonusService.checkoutBonusMppCart({
      employee_id: this.$scope.employeeId,
      bonus_project_id: this.bonus_project_id,
      bonus_id: this.bonus_id,
      cart_id: this.cart._id,
      user_bonus_payment_type_choice_id: this.user_chosen_payment_type['id'],
      checkout_date: this.custom_checkout_date,
      checkout_type_name: this.checkout_type.name
    }).then((checkout_configuration) => {
      this.$timeout(() => {
        this.bonus_checkout_config = checkout_configuration;
        this.handling_fee_brutto = this.bonus_checkout_config.handling_fee_brutto;
        this.can_checkout_bonus = true;
      });
    }).catch((e) => {
      this.$timeout(() => {
        this.bonus_checkout_config = undefined;
        this.can_checkout_bonus = false;
        let errorMsg = e.error && e.error.message || 'Fehlgeschlagen';
        if (e.error.message.includes('not_enough_months')) {
          errorMsg = 'Not enough months';
          this.bonus_start_date_after_bonus_project_end_date = true;
        } else if (e.error.message.includes('not_a_pc_component')) {
          errorMsg = 'This bonus is not a pc component';
        } else if (e.error.message.includes('do exchange')) {
          errorMsg = 'Der Abruf von PC-Leasing ist nicht möglich. Bitte kontaktieren Sie Ihren Berater';
        } else {
          this.budget_not_enough = true;
        }
        this.NotificationService.error(errorMsg);

      });
    });
  }

  getTotalSum() {
    if (this.basket) {
      if (this.basket.value_brutto) {
        return this.basket.value_brutto + this.get_brutto_handling_fee();
      }
      return 0;
    }

    if (this.cartBruttoSum === 0) {
      return 0;
    }
    return this.cartBruttoSum + this.get_brutto_handling_fee();
  }

  getPerMonthValue() {
    if (this.cart && this.cart.articles && this.cart.articles.length > 0) {
      return this.bonus_checkout_config.per_month_value_netto;
    } else {
      return 0;
    }
  }

  /**
   *
   * Checks if one or more cart articles have errors.
   *
   * @param {number} articleIndex - Array index of cart articles
   */
  checkForErrors(articleIndex = -1) {
    let errors = false;

    for (let i = 0; i < this.cart.articles.length; i++) {
      let cCA = this.cart.articles[i];
      if (articleIndex && i === articleIndex) {
        cCA.error = false;
      }

      if (cCA.error) {
        errors = true;
        break;
      }
    }

    this.errors = errors;
  }

  validateCart() {
    let general = false
    let mobile = false;

    for (let i = 0; i < this.cart.articles.length; i++) {
      let article = this.cart.articles[i],
        shortNr = article.Artikelnummer.substring(0, 2);

      for (let j = 0; j < this.requiredProductGroup.length; j++) {
        let productGroup = this.requiredProductGroup[j];
        if (shortNr === productGroup) {
          general = true;
          break;
        }
      }

      for (let j = 0; j < this.mobileRequiredGroups.length; j++) {
        let productGroup = this.mobileRequiredGroups[j];
        if (shortNr === productGroup) {
          mobile = true;
          break;
        }
      }
    }

    this.$timeout(() => {
      this.isValidCart = general;
      this.isMobileValidCart = mobile;

      if (this.is_bonus) {
        this.is_over_bonus_brutto_limit = this.calculate_overspending_brutto_amount() > 0;
      } else {
        this.is_over_bonus_brutto_limit = true;
      }
    }, 0);
  }

  async updateDBCartforOldUpdate() {
    let sum = 0;

    for (let i = 0; i < this.cart.articles.length; i++) {
      let article = this.cart.articles[i];
      sum += article.price;
      this.dbCart.articles[i].price = article.price;
      this.dbCart.articles[i].netprice = article.netto;
    }

    if (!this.is_bonus) {
      this.dbCart.leasing = await this.mppService.updateLeasing(this.dbCart);
    }
    this.dbCart.sum = sum;
  }

  canCheckoutBonus() {
    if (this.is_bonus) {
      if (!this.isPaymentTypeSelected() && this.is_overspending_allowed) {
        return false;
      }

      if (this.hasUnfinishedBuyingProcess) {
        return false;
      }

      return this.isValidCart ? this.can_checkout_bonus : false;
    }
  }

  hasBoniNr() {
    let boni_nr = this.lodash.get(this.customer_configration, 'neo_config.pc.boni_nr');
    return !!parseInt(boni_nr);
  }

  canCheckout() {
    if (!this.hasBoniNr()) {
      return false;
    }

    if (this.is_bonus) {
     return this.canCheckoutBonus();
    }

    //TODO: This condition has to be split for better readability
    return ((this.cart && this.cart.articles.length) || (this.basket && this.basket.articles.length && this.can_do_checkout))
      && this.validBruttoMinimum
      && !this.errors
      && this.isValidCart
      && !this.hasUnfinishedBuyingProcess;
  }

  async handleBonusPaymentMethodChange(type) {
    if (type === 'exchange') {
      this.models.isPaymentMethodBudget = false;
      this.models.isPaymentMethodExchange = true;
    } else {
      this.models.isPaymentMethodExchange = false;
      this.models.isPaymentMethodBudget = true;
    }

    this.user_chosen_payment_type = this.BonusService.getUserChosenBonusPaymentType(type);

    await this.setCheckoutConfig();
    this.validateCart();
    // console.log('handleBonusPaymentMethodChange');
    // if (this.canChangeBonusPaymentMethod()){
    //   this.models.isPaymentMethodBudget = !this.models.isPaymentMethodBudget;
    // }
  }

  async completeCheckout(mobileOption) {
    if (!this.canCheckout()) {
      return;
    }

    if (this.neo_component) {
      await this.saveAndUpdateBasketOnComponent();

      this.$state.go('inApp.neo.side.moduleNewpc', {
        neo_component: this.neo_component,
        to_add_to_checkout_basket: true
      });
    } else {
      return this.prepareAndSetSavedFlag(mobileOption);
    }
  }

  /**
   *
   * Will set the cart to a state where a checkout could be performed.
   *
   */
  async prepareAndSetSavedFlag(mobileOption = false) {
    if (!this.hasArticles && !this.dbCart.savedMPP || this.errors || !this.isValidCart || !this.validBruttoMinimum || !this.canCheckoutBonus()) {
      return;
    }

    if (this.oldUpdate) {
      await this.updateDBCartforOldUpdate();
      await this.save();
      this.oldUpdate = false;
    }

    let save = true, errorIndex = 0;

    for (let i = 0; i < this.cart.articles.length; i++) {
      let article = this.cart.articles[i];

      if (article.quantity === 0) {
        errorIndex = i;
        save = false;

        await this.removeArticle(errorIndex);
        break;
      }
    }

    if (!save) {
      await this.prepareAndSetSavedFlag();
    }

    if (this.is_bonus) {
      this.save().then(() => {
        // this.AlertService.message('Warenkorb gespeichert.');
        return this.goToExtras();
      });
    } else {
      this.customerService.getNeoProjectByEmployeeId(this.$scope.employeeId).then(neoProject => {
        // check if user has an active neo pc component
        if (neoProject.neo_components.pc.enabled) {
          this.mppService.getTotals(this.$scope.employeeId).then(rates => {

            this.mppService.saveLeasingToMPP(this.$scope.employeeId, (Math.round(rates.leasing * 100) / 100)).then(result => {
              if (result) {
                if (this.dbCart.savedMPP) {
                  this.save();
                  console.log('before save \n', this.vbmData.employees[this.$scope.employeeId]);
                  this.vbmData.getData(this.$scope.employeeId).then(
                    () => {
                      this.$q.all(  this.goToExtras(mobileOption));
                    },
                  );
                } else {
                  this.dbCart.savedMPP = true;
                  this.dbCart.state = 'saved';
                  this.save();
                  this.$q.all(this.goToExtras(mobileOption));
                }

                this.NotificationService.message('Warenkorb gespeichert.');

              } else {
                this.NotificationService.error('Speichern fehlgeschlagen.');
              }
            });
          });
        } else {
          this.NotificationService.error('PC-Komponente nicht verfügbar.');
          this.route(); // won't do anything, stays on the same page but triggers change detection
        }
      });
    }
  }

  async goToExtras(mobileOption) {
    let mobile = mobileOption ? 'inApp.neo.side.modulehandy' : 'inApp.neo.side.modulepc';
    this.loading = true;
    let latest_calc = await this.vbmData.getData(this.$scope.employeeId);
    this.loading = false;
    if (this.is_bonus) {
      this.route();
    } else {

      // this is done because we have to ensure that the PC value is = to all active baskets
      let rates = await this.mppService.getTotals(this.$scope.employeeId);

      if (latest_calc.neo_components.pc.value === (Math.round(rates.leasing * 100) / 100)) {
        this.route(mobile);
      }
    }

  }

  route(state) {
    if (!this.$scope.alternateUser) {
      if (this.isAdvisor) {
        window.open('/berater?employeeId=' + this.$scope.employeeId, '_self');
      } else {
        let absUrl = this.$state.href(state, {absolute: true});
        window.open(absUrl + '?employeeId=' + this.$scope.employeeId + '#values', '_self');
      }
    } else if (this.is_bonus) {
      this.employee_bonus_checkout_config.components[this.bonus_component_name].cart = {
        netto_sum: this.cartValueNetto,
        brutto_sum: this.cartBruttoSum,
        _id: this.cart._id
      };
      this.loading = true;
      this.BonusService.checkoutBonusMppCart({
        employee_id: this.$scope.employeeId,
        bonus_project_id: this.bonus_project_id,
        bonus_id: this.bonus_id,
        cart_id: this.cart._id,
        user_bonus_payment_type_choice_id: this.user_chosen_payment_type['id'],
        checkout_date: this.custom_checkout_date,
        checkout_type_name: this.checkout_type.name
      }).then((checkout_configuration) => {
        if (checkout_configuration) {
          this.employee_bonus_checkout_config[this.checkout_type.key][this.bonus_component_name] = this.lodash.merge(this.employee_bonus_checkout_config[this.checkout_type.key][this.bonus_component_name], checkout_configuration);
          this.setBonusCache({
            ...this.getBonusCache(),
            employee_bonus_checkout_config: this.employee_bonus_checkout_config,
          });
          //TODO: refactor to send bonus_id instead for bonus_component_name
          this.$state.go('inApp.bonus.dashboard.main', {
            mpp_data: {
              employee_bonus_checkout: this.employee_bonus_checkout_config,
              bonus_component_name: this.bonus_component_name,
              bonus_limits: this.bonus_limits,
              custom_checkout_date: this.custom_checkout_date,
              checkout_type: this.checkout_type
            },
            bonusProjectId: this.bonus_project_id
          });

        } else {
          this.NotificationService.error('SUM TOO LARGE');
        }
      }).catch((e) => {
        this.NotificationService.error(e.error.message);
      });
    } else {
      this.$state.go(state, {'#': 'values'});
    }
  }

  async calcAdvantage() {

    let data = JSON.parse(JSON.stringify(this.employee.data));
    let result;
    if (this.neo_component) {
      result = await this.neo_component.calculateAdvantage();
    } else {
      data.neo_components.pc.value = this.leasing;
      data.neo_components.pc.enabled = true;

      result = await this.vbmService.getSingleResult(data, 'pc');
    }

    let nettoInvest = (result.before.tax.net_auszahlung - result.final.tax.net_auszahlung);
    this.totalNettoInvest = nettoInvest * 24;
    if (this.neo_component) {
      this.lastPayment = this.neo_component.basket.value_brutto * this.endprice_percentage;
    } else {
      this.lastPayment = this.cartBruttoSum * this.endprice_percentage;
    }
    this.totalExpense = this.totalNettoInvest + this.lastPayment;
    this.relRate = this.totalExpense / 24;
    this.advPercent = (1 - (this.totalExpense / this.cartBruttoSum)) * 100;

    this.$timeout(() => {
      this.showAdvantage = true;
    });
  }

  goBackToExtras() {
    this.$state.go('inApp.neo.side.verguetung');
  }

  deleteCart() {
    this.dialogService
      .frageDialog('Durch das Zurücksetzen wird der gesamte Warenkorbinhalt entfernt. Fortfahren?', 'Warenkorb zurücksetzen')
      .then(
        () => {
          this.mppService.cockpitDelete(this.cart.employeeId, this.cart._id).then(result => {
            if (result) {
              location.reload();
            }
          });
        },
        () => {
        }
      );
  }

  isPaymentTypeSelected() {
    if (!this.is_overspending_allowed) {
      return true;
    }
    return this.models.isPaymentMethodExchange || this.models.isPaymentMethodBudget;
  }
}

MppController.$inject = $inject;
