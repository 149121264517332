angular
  .module( 'schemaForm' )
  .config( [
    'schemaFormDecoratorsProvider',
    function ( decoratorsProvider ) {
      var base = 'app-ajs/directives/schemaforms/';

      decoratorsProvider.defineDecorator( 'bootstrapDecorator', {
        textarea: {
          template: base + 'textarea.html',
          replace: false
        },
        fieldset: {
          template: base + 'fieldset.html',
          replace: false
        },
        box: {
          template: base + 'box.html',
          replace: false
        },
        wizardbox: {
          template: base + 'wizardbox.html',
          replace: false
        },
        field4: {
          template: base + 'field4.html',
          replace: false
        },
        components: {
          template: base + 'components.html',
          replace: false
        },
        baustein: {
          template: base + 'baustein.html',
          replace: false
        },
        customclassdiv: {
          template: base + 'customclassdiv.html',
          replace: false
        },
        customclasscontainer: {
          template: base + 'customclasscontainer.html',
          replace: false
        },
        list: {
          template: base + 'list.html',
          replace: false
        },
        documentdownloads: {
          template: base + 'documentdownloads.html',
          replace: false
        },
        fileinput: {
          template: base + 'fileinput.html',
          replace: true
        },
        adressAB: {
          template: base + 'adressAB.html',
          replace: false
        },
        /*fieldset: {template: base + 'fieldset.html', replace: true, builder: function(args) {
          var children = args.build(args.form.items, args.path + '.items');
          console.log('fieldset children frag', children.childNodes)
          args.fieldFrag.childNode.appendChild(children);
        }},*/
        percent: {
          template: base + 'percent.html',
          replace: false
        },
        array: {
          template: base + 'array.html',
          replace: false
        },
        tabarray: {
          template: base + 'tabarray.html',
          replace: false
        },
        tabs: {
          template: base + 'tabs.html',
          replace: false
        },
        table: {
          template: base + 'table.html',
          replace: false
        },
        section: {
          template: base + 'section.html',
          replace: false
        },
        conditional: {
          template: base + 'section.html',
          replace: false
        },
        circletext: {
          template: base + 'circletext.html',
          replace: false
        },
        progressbar: {
          template: base + 'progressbar.html',
          replace: false
        },
        bausteinselect: {
          template: base + 'bausteinselect.html',
          replace: false
        },
        bausteinselectdisabled: {
          template: base + 'bausteinselectdisabled.html',
          replace: false
        },
        actions: {
          template: base + 'actions.html',
          replace: false
        },
        select: {
          template: base + 'select.html',
          replace: false
        },
        span: {
          template: base + 'span.html',
          replace: false
        },
        checkbox: {
          template: base + 'checkbox.html',
          replace: false
        },
        switchbox: {
          template: base + 'switchbox.html',
          replace: false
        },
        checkboxes: {
          template: base + 'checkboxes.html',
          replace: false
        },
        number: {
          template: base + 'default.html',
          replace: false
        },
        instantupdate: {
          template: base + 'instantupdate.html',
          replace: false
        },
        password: {
          template: base + 'default.html',
          replace: false
        },
        submit: {
          template: base + 'submit.html',
          replace: false
        },
        button: {
          template: base + 'submit.html',
          replace: false
        },
        locationselect: {
          template: base + 'locationselect.html',
          replace: false
        },
        radios: {
          template: base + 'radios.html',
          replace: false
        },
        'radios-inline': {
          template: base + 'radios-inline.html',
          replace: false
        },
        radiobuttons: {
          template: base + 'radio-buttons.html',
          replace: false
        },
        help: {
          template: base + 'help.html',
          replace: false
        },
        servicebox: {
          template: base + 'servicebox.html',
          replace: false
        },
        'default': {
          template: base + 'default.html',
          replace: false
        },
        'iframe-popup': {
          template: base + 'iframe-popup.html',
          replace: false
        }
      }, [] );

      //manual use directives
      decoratorsProvider.createDirectives( {
        textarea: base + 'textarea.html',
        select: base + 'select.html',
        checkbox: base + 'checkbox.html',
        switchbox: base + 'switchbox.html',
        checkboxes: base + 'checkboxes.html',
        number: base + 'default.html',
        submit: base + 'submit.html',
        button: base + 'submit.html',
        text: base + 'default.html',
        date: base + 'default.html',
        password: base + 'default.html',
        datepicker: base + 'datepicker.html',
        input: base + 'default.html',
        radios: base + 'radios.html',
        'radios-inline': base + 'radios-inline.html',
        radiobuttons: base + 'radio-buttons.html',
      } );

    }
  ] ).directive( 'sfFieldset', function () {
  return {
    transclude: true,
    scope: true,
    template: require('./fieldset-trcl.html'),
    link: function ( scope, element, attrs ) {
      scope.title = scope.$eval( attrs.title );
    }
  };
} )
  .directive( 'circlebar', ['', function () {
    return {
      restrict: 'A',
      controller: function ( $element, $scope ) {
        if ( !$scope.form.percent )
          return null;
        var ctx = $element[ 0 ].getContext( '2d' ),
          imd = null,
          circ = Math.PI * 2,
          quart = Math.PI / 2,

          w = 130,
          h = 130,
          s = 4.0;


        ctx.beginPath();
        ctx.strokeStyle = $scope.form.color;
        ctx.lineCap = 'square';
        ctx.closePath();
        ctx.fill();
        ctx.lineWidth = s;

        imd = ctx.getImageData( 0, 0, w, h );

        var draw = function ( current ) {
          ctx.putImageData( imd, 0, 0 );
          ctx.beginPath();
          ctx.arc( w / 2.0, h / 2.0, w / 2.0 - s / 2, +(quart), ((circ) * current) + quart, false );
          ctx.stroke();
        };

        draw( parseInt( $scope.form.title ) / 100.0 );
      }
    };
  }] ).filter( 'vnimage', ['',function () {
  return function ( input, opt ) {
    var img = 'Sonstiges';

    switch (input) {
      case 'sachbezug':
        img = 'sachbezug';
        break;
      case 'essenscheck':
        img = 'essenscheck';
        break;
      case 'VMA_PST':
        img = 'VMA';
        break;
      case 'VMA_STSVFREI':
        img = 'VMA';
        break;
      case 'handy':
        img = 'handy';
        break;
      case 'festnetz':
        img = 'festnetz';
        break;
      case 'internet':
        img = 'internet';
        break;
      case 'kinder':
        img = 'kinder';
        break;
      case 'bgmbudget':
        img = 'bgmbudget';
        break;
      case 'jobticket':
        img = 'jobticket';
        break;
      case 'entfernung':
        img = 'entfernung';
        break;
      case 'dienstwagen_miete':
        img = 'dienstwagen_miete';
        break;
      case 'werbeflaeche':
        img = 'werbeflaeche';
        break;
      case 'pc':
        img = 'pc';
        break;
      case 'heimarbeit':
        img = 'heimarbeit';
        break;
      case 'personal_rabatt':
        img = 'personal_rabatt';
        break;
      case 'erholungsbeihilfe':
        img = 'erholungsbeihilfe';
        break;
      case 'Sonstiges':
        img = 'Sonstiges';
        break;
      case 'EBike':
        img = 'EBike';
        break;
      case 'berufskleidung':
        img = 'berufskleidung';
        break;
    }

    if ( opt ) img += '_' + opt;

    return img + '.png';
  };
}] );
