import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/shared/models/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import * as moment from 'moment';
import {
  availableCashbackComponentModel,
  bonusTypes,
  invoiceValidPeriodsModel,
} from './cashback-receipt-upload-dialog.model';
import { formatDateToYYYYMMDD } from "../../../shared/common";

@Component({
  selector: 'vn-cashback-receipt-upload-dialog',
  templateUrl: './cashback-receipt-upload-dialog.component.html',
  styleUrls: ['./cashback-receipt-upload-dialog.component.scss'],
})
export class CashbackReceiptUploadDialogComponent
  extends BaseComponent
  implements OnInit
{
  private employeeId!: number;
  public formGroup!: FormGroup;
  public bonusCategories!: availableCashbackComponentModel[];
  public invoiceValidPeriods: invoiceValidPeriodsModel[] = [
    {
      name: '1 Tag',
      value: 'one_day',
    },
    {
      name: '1 Woche',
      value: 'one_week',
    },
    {
      name: '1 Monat',
      value: 'one_month',
    },
    {
      name: '3 Monate',
      value: 'three_months',
    },
    {
      name: '6 Monate',
      value: 'six_months',
    },
    {
      name: '12 Monate',
      value: 'twelve_months',
    },
  ];

  constructor(
    @Inject('Made') private made: any,
    @Inject('BonusService') private bonusService: any,
    @Inject('$location') private location: any,
    private notificationService: AlertService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.employeeId = this.made.user.valuenet_id;
    this.initFormGroup();
    await this.initAvailableCashbackComponents();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      component: this.formBuilder.control('', [Validators.required]),
      requestedValue: this.formBuilder.control('', [Validators.min(0), Validators.required]),
      receiptDate: this.formBuilder.control(moment().toDate(), [
        Validators.required,
      ]),
      firstValidDate: this.formBuilder.control(moment().toDate(), [
        Validators.required,
      ]),
      receiptValidPeriod: this.formBuilder.control('', [Validators.required]),
      bgmNotUsed: this.formBuilder.control(false, [Validators.required]),
      files: this.formBuilder.control('', [Validators.required]),
    });
  }

  async initAvailableCashbackComponents() {
    const options = { employee_id: this.employeeId };

    const [availableCashbackComponents, availableBonusComponents] =
      await Promise.all([
        this.bonusService.getAvailableCashbackComponents(options),
        this.bonusService.getAvailableBonusComponents(false),
      ]);

    const bonusCategories = availableCashbackComponents.map(
      (availableCashbackComponents: any) => {

        const componentName = availableCashbackComponents.component_key;
        const componentTitle = availableCashbackComponents.component_display;
        const bonusProjectIds = availableCashbackComponents.bonus_project_ids;


        return {
          value: componentName,
          name: componentTitle,
          bonusProjectIds: bonusProjectIds
        };
      }
    );

    this.bonusCategories = bonusCategories;
  }

  public addFileToFormData(files: File[]) {
    this.formGroup.patchValue({ files });
  }

  async createCashbackRequest() {

      this.setLoading$(true);

      const employeeData = {
        id: this.employeeId,
        currentUrl: this.location.absUrl(),
      };

      let {
        component,
        requestedValue,
        receiptDate,
        firstValidDate,
        receiptValidPeriod,
        files,
      } = this.formGroup.value;

      const selectedBonus = this.bonusCategories.find(
        (bonus) => this.formGroup.value.component === bonus.value
      );

      const bonusData = {
        bonusProjectId: selectedBonus?.bonusProjectIds[0],
        name: selectedBonus?.value,
      };

      //@ts-ignore
      let receiptDateFormatted = formatDateToYYYYMMDD(receiptDate);
      //@ts-ignore
      let firstValidDateFormatted = formatDateToYYYYMMDD(firstValidDate);

      const requestPayload = {
        "employeeData": employeeData,
        "bonusData": bonusData,
        "component": component,
        "requestedValue": requestedValue,
        "receiptDate": receiptDateFormatted,
        "firstValidDate": firstValidDateFormatted,
        "receiptValidPeriod": receiptValidPeriod,
        "files": files
      };

      return this.bonusService.createCashbackRequest(requestPayload).then(
        (res: any) => {
          if (res == 'success') {
            this.notificationService.message('Ihre Anfrage wurde übermittelt.');
          } else if (res == 'max_requests_for_current_month') {
            this.notificationService.error('Es können keine weiteren Belege in diesem Monat eingereicht werden.');
          } else {
            this.notificationService.error(res);
          }
        }).catch((err: any) => {
        this.notificationService.error('Fehlgeschlagen.');
      }).finally(() => {
        this.setLoading$(false);
      })
  }

  public isBonusSelected() {
    return this.formGroup.value.component;
  }

  public isBonusJobTicket(): boolean {
    return this.formGroup.value.component === bonusTypes.jobTicket;
  }

  public isBonusBgm(): boolean {
    return this.formGroup.value.component === bonusTypes.bgm;
  }

  public isFormValid() {
    const {
      component,
      requestedValue,
      receiptDate,
      firstValidDate,
      receiptValidPeriod,
      files,
      bgmNotUsed
    } = this.formGroup.value;

    let commonInputsValid = [
      component,
      requestedValue,
      receiptDate,
      files,
    ].every(elem => !!elem)

    commonInputsValid = commonInputsValid && requestedValue > 0;

    if (component === bonusTypes.kindergarten) {
      return commonInputsValid;
    } else
    if (component === bonusTypes.bgm) {
      return commonInputsValid && bgmNotUsed;
    } else
    if (component === bonusTypes.jobTicket) {
      return commonInputsValid
        && firstValidDate
        && receiptValidPeriod;
    }
  }

  public handleCheckbox(isChecked: boolean) {
    this.formGroup.patchValue({
      bgmNotUsed: isChecked
    })
  }
}
