import {Injectable} from '@angular/core';

import {
  CarLeasingBasket,
  DrivingLicenseFileApiResponse,
  UploadRequestsBySlotIdApiResponse
} from "../../models/car-leasing-basket.model";
import {CarLeasingTicketCreation} from '../../models/car-leasing-ticket-creation.model';
import {ContractProcessState} from '../../models/contract-process-state.model';

import {FirstCheckState} from "../../models/first-check-state.model";


@Injectable()
export class BackendCarLeasingBasketMapper {

  public fromApi(o: any): CarLeasingBasket {

    let contractProcessData = o['control_process_data']
    let contractSigningProcess = o['contract_signing_process']
    let otrs = null;

    if (o['otrs']) {
      otrs = new CarLeasingTicketCreation(o['otrs']['ticket_number'], o['otrs']['ticket_url'])
    }

    let contractProcesses: any = [];
    contractSigningProcess.forEach((item: any) => {
      contractProcesses.push(
        {
          fileId: item['file_id'],
          state: BackendCarLeasingBasketMapper.contractProcessStateFromApi(
            item['state'],
            item['comment'],
          ),
          comment: item['comment'],
          process_type: item['process_type']
        }
      );
    });

    return new CarLeasingBasket(
      o['id'],
      o['employee_id'],
      o['customer_id'],
      BackendCarLeasingBasketMapper.firstCheckStateFromApi(o['first_check_state']),
      o['order_id'],
      BackendCarLeasingBasketMapper.firstCheckFilesFromApi(o['first_check_files']),
      o['checkout_date'],
      o['preferred_delivery_date'],
      o['is_ready_to_sign'],
      {
        fileId: contractProcessData['file_id'],
        state: BackendCarLeasingBasketMapper.contractProcessStateFromApi(
          contractProcessData['state'],
          contractProcessData['comment'],
        ),
        comment: contractProcessData['comment'],
        process_type: contractProcessData['process_type']
      },
      contractProcesses,
      otrs,
      o['checkout_configuration'],
      o['approval_state'],
      o['delivery_phone'],
      o['is_cancelled']
    );
  }


  public static firstCheckFilesFromApi(
    files: {
      driving_license_files: DrivingLicenseFileApiResponse,
      upload_requests_by_slot_id: {
        front?: {comment: string, file_id: string},
        back?: {comment: string, file_id: string},
        selfie?: {comment: string, file_id: string},
      } | null
    }
    ): { drivingLicenseFiles: DrivingLicenseFileApiResponse, uploadRequestsBySlotId: UploadRequestsBySlotIdApiResponse } {

      let uploadRequestsBySlot: any = {};
      if (files.upload_requests_by_slot_id) {
        for (const [slot, data] of Object.entries(files.upload_requests_by_slot_id)) {
          let res: any = data

          uploadRequestsBySlot[slot] = {
            fileId: res.file_id,
            comment: res.comment
          }
        }
      }

      return {
        'drivingLicenseFiles': files.driving_license_files,
        'uploadRequestsBySlotId': Object.keys(uploadRequestsBySlot).length ? uploadRequestsBySlot : null
      };
    }

    public static contractProcessStateFromApi(s: string, uploadRequestComment: string): ContractProcessState {
    if (s == 'not_signed' && uploadRequestComment) return ContractProcessState.AwaitingUpload;
    if (s == 'not_signed' && !uploadRequestComment) return ContractProcessState.NotStarted;
    if (s == 'awaiting_manual_signing_approval') return ContractProcessState.AwaitingApproval;
    if (s == 'signed') return ContractProcessState.Approved;
    if (s == 'pre_contract_signed') return ContractProcessState.PreContractSigned;

    else throw Error('Unknown contract process state "' + s + "'");
  }

  public static firstCheckStateFromApi(s: string): FirstCheckState {
    if (s == 'awaiting_approval') return FirstCheckState.AwaitingApproval;
    if (s == 'awaiting_upload') return FirstCheckState.AwaitingUpload;
    if (s == 'approved') return FirstCheckState.Approved;
    if (s == 'rejected') return FirstCheckState.Rejected;
    else throw Error('Unknown first check state "' + s + "'");
  }

}
